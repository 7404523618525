import React, { useEffect, useState } from "react"
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban"
import { Button, Header } from "../UI"
import DataTable from "react-data-table-component"
import moment from "moment"
import { useStateContext } from "../contexts/ContextProvider"
import { useDispatch } from "react-redux"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"

const Workflow = () => {
  const dispatch = useDispatch()
  const { currentColor, currentMode } = useStateContext()
  const [isFlowOpen, setisFlowOpen] = useState(false)
  const [isData, setIsData] = useState({
    sendMsg: false,
    sendEmail: false,
  })
  const [dataList, setDataList] = useState([])

  const workflowData = [
    {
      id: "Task 1",
      Title: "Task - 29001",
      Status: "Open",
      Summary: "Analyze the new requirements gathered from the customer.",
      Type: "Story",
      Priority: "Low",
      Tags: "Analyze,Customer",
      Estimate: 3.5,
      Assignee: "Nancy Davloio",
      RankId: 1,
      Color: "#02897B",
      ClassName: "e-story, e-low, e-nancy-davloio",
    },
    {
      id: "Task 2",
      Title: "Task - 29002",
      Status: "InProgress",
      Summary: "Improve application performance",
      Type: "Improvement",
      Priority: "Normal",
      Tags: "Improvement",
      Estimate: 6,
      Assignee: "Andrew Fuller",
      RankId: 1,
      Color: "#673AB8",
      ClassName: "e-improvement, e-normal, e-andrew-fuller",
    },
  ]

  const workflowGrid = [
    {
      name: "ID",
      // selector: (row) => moment(row.bookingTimeSlot).format("hh:mm A"),
      selector: (row, index) => index + 1,
      // sortable: true,
      // reorder: true,
    },
    {
      name: "Name",
      selector: (row, index) =>
        row.name ? row.name : ` Workflow ${index + 1} `,
      sortable: true,
      // reorder: true,
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("hh:mm A"),
      sortable: true,
      // reorder: true,
    },
    {
      name: "Action",
      // selector: (row) => row.paid,
      cell: (row) => (
        <button className="flex bg-green-100 text-green-600 space-x-1 border-2 shadow-sm shadow-green-700 rounded p-2 uppercase font-semibold">
          edit
        </button>
      ),
      // sortable: true,
      // reorder: true,
    },
  ]

  const workflowTypes = [
    {
      id: 0,
      title: "Send Reminders of Bookings",
      value: "reminders",
      type: [
        {
          id: 0,
          title: "Based On Hours",
          value: "hours",
          occurance: [
            {
              id: 0,
              name: "1 hour Before Booking",
              value: 1,
            },
            {
              id: 1,
              name: "3 hours Before Booking",
              value: 3,
            },
            {
              id: 2,
              name: "12 hours Before Booking",
              value: 12,
            },
          ],
        },
        {
          id: 1,
          title: "Based On Days",
          value: "days",
          occurance: [
            {
              id: 0,
              name: "1 Day Before Booking",
              value: 1,
            },
            {
              id: 1,
              name: "2 Day Before Booking",
              value: 2,
            },
          ],
        },
        {
          id: 2,
          title: "Based On Weeks",
          value: "weeks",
          occurance: [
            {
              id: 0,
              name: "1 Weeks Before Booking",
              value: 1,
            },
            {
              id: 1,
              name: "2 Weeks Before Booking",
              value: 2,
            },
          ],
        },
      ],
    },
    // {
    //   id: 1,
    //   title: "Send Feedback",
    //   value: "feedback",
    //   type: [
    //     {
    //       id: 0,
    //       title: "Send Reminders",
    //       value: "reminders",
    //       occurance: [
    //         {
    //           id: 0,
    //           name: "1 Day After",
    //           value: 1,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]

  const handleWorkflow = (event) => {
    const { name, value, type, lang } = event.target

    if (lang === "number") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: parseInt(value),
      }))
    } else if (name === "type") {
      const values = JSON.parse(value)

      const newValues = {
        id: values.id ?? "",
        title: values.title ?? "",
        value: values.value ?? "",
      }
      setIsData((prevData) => ({
        ...prevData,
        [name]: newValues,
      }))
    } else if (name === "when") {
      setIsData((prevData) => ({
        ...prevData,
        [name]: JSON.parse(value),
      }))
    } else {
      setIsData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
    const createTime = new Date()
    setIsData((prevData) => ({
      ...prevData,
      createdAt: createTime.toISOString(),
    }))
  }

  useEffect(() => {
    console.log("handleWorkflow", isData)
  }, [isData])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("workflow"))
    setDataList(data)
    setIsData({})
  }, [])

  useEffect(() => {
    if (isData.message) {
      localStorage.setItem("workflow", JSON.stringify(dataList))
      setIsData({})
      dispatch(
        openToastify(
          "New Workflow Saved",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        )
      )

      setTimeout(() => {
        dispatch(
          openToastify(
            "New Workflow Start from Next Day",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_INFO()
          )
        )
      }, 4000)
    }
  }, [dataList])

  const generateRandomId = () => {
    // You can generate a random ID using current timestamp and random number
    return `${Date.now()}_${Math.floor(Math.random() * 10000)}`
  }

  const handleCreate = (e) => {
    e.preventDefault()

    if (!isData?.sendMsg && !isData?.sendEmail) {
      dispatch(
        openToastify(
          "Select Message Type First",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      return
    }

    const newData = {
      ...isData,
      id: generateRandomId(), // Generate a random ID for the new entry
      createdAt: new Date().toISOString(), // Add current timestamp for `createdAt`
    }
    console.log(newData, "newData")

    if (dataList) {
      setDataList((prevData) => [...prevData, newData])
    } else {
      setDataList([newData])
    }

    setisFlowOpen(false)
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Workflow" />
      <div>
        <button
          onClick={() => setisFlowOpen(true)}
          className="bg-yellow-light h-8 w-20 rounded font-semibold text-white"
        >
          Create
        </button>
      </div>
      <DataTable
        // title="Coupons List"
        columns={workflowGrid}
        data={dataList ?? []}
        pagination
        fixedHeader
        selectableRows
        fixedHeaderScrollHeight="480px"
        // onChangePage={handlePageChange}
        // paginationTotalRows={100}
        // onSelectedRowsChange={handleSelectedRowsChange}
      />

      {isFlowOpen && (
        <div className="absolute bg-white inset-0 z-20 bg-opacity-35 backdrop-blur-md flex justify-center items-center">
          <div className="md:w-[80%] lg:h-[90%] h-[80%] bg-white">
            <div
              className="flex justify-end -mt-2 -mr-2 cursor-pointer"
              onClick={() => {
                setisFlowOpen(false)
              }}
            >
              <svg
                className="size-6 bg-white rounded border border-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <form
              onSubmit={handleCreate}
              className="flex h-full flex-col md:flex-row w-full p-5 justify-between lg:divide-x-2 divide-gray-400"
            >
              <div className="lg:w-[70%] lg:p-2 lg:bg-gray-200 flex justify-center overflow-y-scroll">
                <div className="lg:w-[90%] h-full bg-white rounded flex flex-col p-10 space-y-5">
                  {/* WorkFlow Name */}
                  <div className="flex flex-col">
                    <label htmlFor="name">WorkFlow Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="border h-10 px-2"
                      placeholder="Name"
                      onChange={handleWorkflow}
                      required
                    />
                  </div>

                  {/* Select Workflow */}
                  <div className="flex flex-col">
                    <label htmlFor="workflowTypeId">Select Workflow</label>
                    <select
                      name="workflowTypeId"
                      id="workflowTypeId"
                      lang="number"
                      className="border h-10 px-2"
                      onChange={(e) => handleWorkflow(e)}
                      value={isData?.workflowTypeId ?? NaN}
                      required
                    >
                      <option value="">Select Flow</option>
                      {workflowTypes.map((data, index) => {
                        return (
                          <option key={index + "action"} value={data.id}>
                            {data.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  {/* Select Type */}
                  <div className="flex flex-col">
                    <label htmlFor="type">Select Type</label>
                    <select
                      name="type"
                      id="type"
                      lang="object"
                      className="border h-10 px-2"
                      onChange={(e) => handleWorkflow(e)}
                      required
                    >
                      <option value="">Select Type</option>
                      {workflowTypes[isData?.workflowTypeId]?.type.map(
                        (data, index) => {
                          return (
                            <option
                              key={index + "type"}
                              value={JSON.stringify(data)}
                            >
                              {data.title}
                            </option>
                          )
                        }
                      )}
                    </select>
                  </div>

                  {/* Select When */}
                  <div className="flex flex-col">
                    <label htmlFor="when">When</label>
                    <select
                      name="when"
                      id="when"
                      lang="object"
                      className="border h-10 px-2"
                      onChange={(e) => handleWorkflow(e)}
                      required
                    >
                      <option value="">Select When</option>
                      {workflowTypes[isData?.workflowTypeId]?.type[
                        isData?.type?.id
                      ]?.occurance.map((data, index) => {
                        return (
                          <option
                            key={index + "type"}
                            value={JSON.stringify(data)}
                          >
                            {data.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="flex space-x-3">
                    {/* Send Email */}
                    <div className="space-x-2">
                      <input
                        className="mt-1"
                        type="checkbox"
                        name="sendEmail"
                        id="sendEmail"
                        checked={isData?.sendEmail}
                        onChange={(e) => {
                          setIsData((item) => ({
                            ...item,
                            sendEmail: e.target.checked,
                          }))
                        }}
                      />
                      <label htmlFor="sendEmail">Send Email?</label>
                    </div>
                    {/* Send SMS */}
                    <div className="space-x-2">
                      <input
                        className="mt-1"
                        type="checkbox"
                        name="sendMsg"
                        id="sendMsg"
                        checked={isData?.sendMsg}
                        onChange={(e) => {
                          setIsData((item) => ({
                            ...item,
                            sendMsg: e.target.checked,
                          }))
                        }}
                      />
                      <label htmlFor="sendMsg">Send SMS?</label>
                    </div>
                  </div>

                  {/* Message */}
                  <div className="flex flex-col">
                    <label htmlFor="msg">Message</label>
                    <textarea
                      type="text"
                      id="msg"
                      name="message"
                      rows={5}
                      className="border h- 10 w-full p-2"
                      placeholder="Write message here..."
                      onChange={(e) => handleWorkflow(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="w- [30%] flex-1 h-full p-2">
                {/* Button */}
                <div className="flex h-full items-end justify-end space-x-4">
                  <Button
                    onClick={() => {
                      setisFlowOpen(false)
                    }}
                    color="white"
                    bgColor="gray"
                    text="Cancel"
                    borderRadius="10px"
                  />
                  <Button
                    type="submit"
                    color="white"
                    bgColor={currentColor}
                    text="Save"
                    borderRadius="10px"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Workflow
