import React from "react"
import moment from "moment"

const Footer = () => {
  return (
    <div className="mt- 24">
      <p className="dark:text-gray-200 text-gray-700 text-center m- 20">
        © {moment().format("YYYY")} All Rights Reserved By Boost
      </p>
    </div>
  )
}

export default Footer
