import moment from "moment"
import React, { useEffect, useState } from "react"

const ViewBooking = (props) => {
  const { data, onClick } = props

  useEffect(() => {
    console.log(props)
  }, [])

  return (
    <>
      <div className="fixed inset-0 z-20 bg-black bg-opacity-45 backdrop-blur-sm">
        <div className="flex h-full justify-center items-center">
          <div className="bg-white lg:h-auto h-[80%] lg:w-[70%] w-[90%] rounded flex flex-col">
            <div
              className="flex justify-end -mt-2 -mr-2 cursor-pointer"
              onClick={onClick}
            >
              <svg
                className="size-6 bg-white rounded border border-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>

            <div className="lg:h-[680px] overflow-y-scroll">
              <div className="w-full text-center text-xl">
                <h1>Booking Information</h1>
                <span className="text-sm">
                  Created At:{" "}
                  {moment(data?.createdAt).utc().format("YYYY-MM-DD hh:mm A")}
                </span>
              </div>
              <div className="px-[5%] py-5 overflow-y -scroll space-y-5">
                <div className="flex flex-col">
                  <h1>Personal Details</h1>
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="space-x-2 w- full lg:w- auto flex justify- between">
                      <span>First Name:</span>
                      <span>{data?.firstName}</span>
                    </div>
                    <div className="space-x-2 w- full lg:w- auto flex justify- between">
                      <span>Phone Number:</span>
                      <span>{data?.phoneNumber}</span>
                    </div>
                    <div className="space-x-2 w- full lg:w- auto flex justify- between">
                      <span>Email:</span>
                      <span>{data?.email}</span>
                    </div>
                    <div className="space-x-2 w- full lg:w- auto flex justify- between">
                      <span>Location:</span>
                      <span>{data?.location}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <h1>Booking Details</h1>
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Booking Status:</span>
                      <span
                        className={` px-1 rounded-full capitalize ${
                          data?.bookingStatus === "processing"
                            ? "bg-yellow-light"
                            : data?.bookingStatus === "canceled"
                            ? "bg-red-600"
                            : "bg-green-600"
                        } `}
                      >
                        {data?.bookingStatus}
                      </span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Location Type:</span>
                      <span className="capitalize">{data?.locationType}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Booking Date:</span>
                      <span>
                        {moment(data?.bookingDate).utc().format("YYYY-MM-DD")}
                      </span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Booking Time:</span>
                      <span>
                        {moment(data?.bookingTimeSlot).utc().format("hh:mm A")}
                      </span>
                    </div>
                  </div>
                </div>

                <hr className="h-2 w-full" />
                <div className="flex flex-col">
                  <h1>Services Details</h1>
                  <div className="flex items-start justify-between flex-wrap space-y-5">
                    {data?.booking &&
                      data?.booking.map((value, index) => {
                        return (
                          <>
                            <div className="flex w-full space-x-3">
                              <div className="w-1/2">
                                <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                  <span>Vehicle Type</span>
                                  <span className="capitalize">
                                    {value.service.vehicleType}
                                  </span>
                                </div>
                                <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                  <span>Service Category</span>
                                  <span className="capitalize">
                                    {value.service.serviceName}
                                  </span>
                                </div>
                              </div>

                              <div className="w-1/2">
                                <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                  <span>Service Type</span>
                                  <span className="capitalize">
                                    {value.service.serviceDesc}
                                  </span>
                                </div>
                                <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                  <span>Service Price</span>
                                  <span className="capitalize">
                                    ${value.service.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr className="h-2 w-full" />
                            {value.addonService.map((data, index) => {
                              return (
                                <div className="flex w-full space-x-3">
                                  <div className="w-1/2">
                                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                      <span>AddOn Service</span>
                                      <span className="capitalize">
                                        {data.addonSericeName}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-1/2">
                                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                                      <span>AddOn Price</span>
                                      <span className="capitalize">
                                        ${data.addonPrice}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                            {value.addonService.length ? (
                              <hr className="h-2 w-full" />
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      })}
                  </div>
                </div>
                <div className="flex flex-col">
                  <h1>Discount Details</h1>
                  <div className="flex items-start justify-between flex-wrap space- y-5">
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Coupon Name:</span>
                      <span>{data?.coupon?.couponName}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Max Discount:</span>
                      <span>{data?.coupon?.maxDiscount}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Mini Order Value:</span>
                      <span>{data?.coupon?.minOrderValue}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Expiry Date:</span>
                      <span>
                        {moment(data?.coupon?.expiryDate).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <h1>Bill Details</h1>
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Service Total:</span>
                      <span>${data?.serviceTotal}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Addon Service Total:</span>
                      <span>${data?.addonServiceTotal}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Sub Total:</span>
                      <span>${data?.subTotal}</span>
                    </div>
                  </div>
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="space-x-2 w-full lg:w-auto flex justify-between text-amber-600">
                      <span>Discount:</span>
                      <span>
                        {data?.giftCardDiscountAmount === 0
                          ? ""
                          : `$${data?.giftCardDiscountAmount}`}
                      </span>
                      <span>
                        {data?.couponDiscountAmount === 0
                          ? ""
                          : `$${data?.couponDiscountAmount}`}
                      </span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Tax {data?.tax}%:</span>
                      <span>${data?.taxAmount}</span>
                    </div>
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Other Charges:</span>
                      <span>
                        $
                        {data?.isElectrictyAvailable
                          ? 0
                          : data?.electricityBill}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  {/* <h1>Total</h1> */}
                  <div className="flex items-start justify-between flex-wrap">
                    <div className="space-x-2 w-full lg:w-auto flex justify-between">
                      <span>Total Amount:</span>
                      <span>${data?.totalAmount}</span>
                    </div>
                  </div>
                </div>

                <div>
                  {data?.booking &&
                    data?.booking.map((value, index) => {
                      return (
                        <tr>
                          {value?.images?.length ? (
                            <td colSpan={4}>
                              <span className="e-textlabel">Images</span>
                              <div className="flex w-[100%] overflow-x-scroll">
                                {value?.images?.map((url) => {
                                  return (
                                    <>
                                      <img src={url} alt="" className="w-48" />
                                      {/* <img
                                  src="https://res.cloudinary.com/dsprifizw/image/upload/v1723882081/uploads/image_a6ebd44c24f9.jpg"
                                  alt=""
                                  className="w-48"
                                /> */}
                                    </>
                                  )
                                })}
                              </div>
                            </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      )
                    })}
                </div>

                {data?.note && (
                  <div>
                    <span>Additional Details:</span>
                    <p>{data?.note}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewBooking
