import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reminderMail, reminderMessage } from "../../../axios/api/apiModule"
import { openToastify } from "../../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../../store/config"
import moment from "moment"

const Reminder = () => {
  const dispatch = useDispatch()
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )
  const [workflowData, setWorkflowData] = useState([])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("workflow"))
    setWorkflowData(data || [])
    console.log("workflowData", data)
  }, [])

  const hitReminderSMSApiClient = async (phoneNumber, msg) => {
    try {
      const params = { phoneNumber, msg }
      const response = await reminderMessage(params)
      if (response.success) {
        // dispatch(
        //   openToastify(
        //     `SMS sent successfully to ${phoneNumber}`,
        //     REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        //   )
        // )
      }
    } catch (error) {
      dispatch(
        openToastify(
          error?.response?.data?.msg || "Failed to send SMS",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  const hitReminderEmailApiClient = async (email, htmlContent) => {
    try {
      const parms = {
        email,
        subject: `Reminder for booking`,
        htmlContent,
      }
      const response = await reminderMail(parms)
      if (response.success) {
        // dispatch(
        //   openToastify(
        //     `Email sent successfully to ${email}`,
        //     REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        //   )
        // )
      }
    } catch (error) {
      dispatch(
        openToastify(
          error?.response?.data?.msg || "Failed to send Email",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  const sendReminder = async (booking, workflow) => {
    const bookingTime = new Date(booking.bookingTimeSlot)
    const now = new Date()

    const sendNotification = async () => {
      if (workflow.sendMsg) {
        await hitReminderSMSApiClient(booking.phoneNumber, workflow.message)
      }
      if (workflow.sendEmail) {
        await hitReminderEmailApiClient(booking.email, workflow.message)
      }
    }

    // Function to calculate the exact reminder time based on hours, days, or weeks
    const calculateReminderTime = (unit, value) => {
      switch (unit) {
        case "hours":
          return bookingTime.getTime() - value * 60 * 60 * 1000 // Subtract hours
        case "days":
          return bookingTime.getTime() - value * 24 * 60 * 60 * 1000 // Subtract days
        case "weeks":
          return bookingTime.getTime() - value * 7 * 24 * 60 * 60 * 1000 // Subtract weeks
        default:
          throw new Error("Invalid reminder type")
      }
    }

    // Calculate the exact reminder time
    const reminderTime = new Date(
      calculateReminderTime(workflow.type.value, workflow.when.value)
    )

    // Log booking and reminder times
    console.log(
      "Booking time =",
      bookingTime,
      "Reminder time =",
      reminderTime,
      "Current time =",
      now
    )

    // Ensure that the reminder time is not in the past
    if (reminderTime <= now) {
      console.log("Reminder time is in the past. Skipping reminder.")
      return
    }

    // Check if the reminder should be sent now or scheduled for the future
    if (now >= reminderTime) {
      console.log("Triggering notification now")
      await sendNotification()
    } else {
      // Calculate delay in milliseconds and schedule the notification
      const delay = reminderTime.getTime() - now.getTime()
      console.log(`Scheduling notification to trigger in ${delay} ms`)
      setTimeout(sendNotification, delay)
    }
  }

  useEffect(() => {
    if (workflowData && workflowData.length > 0) {
      const shopBookings = shopBookingData?.bookings || []
      const mobileBookings = mobileBookingData?.bookings || []
      const allBookings = [...shopBookings, ...mobileBookings]

      const processBookingsWithDelay = async () => {
        // Retrieve existing workflows from localStorage, or initialize an empty array
        const storedWorkflows =
          JSON.parse(localStorage.getItem("workflow")) || workflowData

        // Get today's date using moment in 'YYYY-MM-DD' format
        const todayDate = moment().format("YYYY-MM-DD")

        for (let i = 0; i < storedWorkflows.length; i++) {
          const workflow = storedWorkflows[i]

          // Extract the date part from recentActivity if it exists, using moment
          const recentActivityDate = workflow.recentActivity
            ? moment(workflow.recentActivity).format("YYYY-MM-DD")
            : undefined

          // Extract the createdAt date using moment
          const workflowCreatedAtDate = moment(workflow.createdAt).format(
            "YYYY-MM-DD"
          )

          // Check if recentActivity is undefined or not equal to today's date
          // and if workflow createdAt is not equal to today's date

          // TODO: Check conditions !recentActivityDate || recentActivityDate !== todayDate) &&
          // workflowCreatedAtDate !== todayDate
          if (
            (!recentActivityDate || recentActivityDate == todayDate) &&
            workflowCreatedAtDate !== todayDate
          ) {
            for (let j = 0; j < allBookings.length; j++) {
              const booking = allBookings[j]
              // console.log(booking, workflow, "booking,workflow")
              sendReminder(booking, workflow)

              // Delay 1 second before processing the next function call
              await new Promise((resolve) => setTimeout(resolve, 1000))
            }

            // After completing one workflow, update recentActivity and count
            const recentActivity = moment().toISOString()

            // Increment the count (initialize if undefined)
            const runCount = workflow.runCount ? workflow.runCount + 1 : 1

            // Update the workflow with recentActivity and runCount
            storedWorkflows[i] = {
              ...workflow,
              recentActivity,
              runCount,
            }

            // Save the updated workflows back to localStorage
            localStorage.setItem("workflow", JSON.stringify(storedWorkflows))
            console.log(
              "Updated workflows with recentActivity and runCount",
              storedWorkflows
            )
            dispatch(
              openToastify(
                "Remiders setting is completed",
                REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
              )
            )
          } else {
            console.log("all workflows are run tommarrow")
          }
        }
      }

      processBookingsWithDelay()
    }
  }, [workflowData, shopBookingData, mobileBookingData])

  return <></>
}

export default Reminder
