import React, { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom"
import { FiSettings } from "react-icons/fi"
import { TooltipComponent } from "@syncfusion/ej2-react-popups"
import { useStateContext } from "./components/contexts/ContextProvider"
import { Provider, useDispatch, useSelector } from "react-redux"
import store from "./components/store/Store"

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components/UI"
import {
  Dashboard,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorMapping,
  Editor,
} from "./components/pages"
import "./App.css"
import Discount from "./components/pages/Discount"
import LoginScreen from "./loginSignup/LoginScreen"
import RouteIndex from "./routes/RouteIndex"
import { userAction } from "./components/store/actions/userAction"
import { REDUX_STORE_DEFAULTS } from "./components/store/config"
import SignupScreen from "./loginSignup/SignupScreen"
import Tostify from "./components/UI/ToastifyNotify/Tostify"
import logo from "./components/asserts/Spin.gif"
function App() {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext()

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode")
    const currentThemeMode = localStorage.getItem("themeMode")
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor)
      setCurrentMode(currentThemeMode)
    }
  }, [])

  const [isLogin, setIsLogin] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  let { token } = useSelector((state) => state.userReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const data = localStorage.getItem("user")
  const userData = JSON.parse(data)
  const localToken = userData?.token
  const lastActivity = userData?.lastActivity

  useEffect(() => {
    // Retrieve the token and lastActivity from local storage

    console.log(token, "token", localToken)

    // Check if the token is available and whether it has expired (24-hour limit)
    if (localToken) {
      const currentTime = new Date()
      const lastActivityTime = new Date(lastActivity)
      const timeDifferenceInHours =
        (currentTime - lastActivityTime) / (1000 * 60 * 60) // Convert milliseconds to hours

      if (timeDifferenceInHours < 24) {
        // Token is valid and within the 24-hour limit
        navigate("/bookings")
        setIsLogin(true)
      } else {
        // Token is expired
        setIsLogin(false)
        navigate("/")
      }
    } else if (token) {
      // If there's a token from Redux state
      navigate("/bookings")
      setIsLogin(true)
    } else {
      // No token, navigate to login page
      setIsLogin(false)
      navigate("/")
    }

    setTimeout(() => {
      setIsLoader(false)
    }, 150)
  }, [localToken])

  return (
    <>
      <Tostify />
      {!isLoader ? (
        <>
          <Routes>
            <Route path="/" element={<LoginScreen />}></Route>
            {/* <Route path="/register" element={<SignupScreen />}></Route> */}
          </Routes>
          {isLogin && <RouteIndex />}
        </>
      ) : (
        <div className="fixed bg-black w-full h-full items-center justify-center flex">
          <img
            src={logo}
            alt=""
            className="md: w-[5rem] md: h-[5rem] object-cover"
          />
          <span>Loading</span>
        </div>
      )}
    </>
  )
}

export default App
