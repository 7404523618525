import { combineReducers } from "@reduxjs/toolkit"
import userReducer from "./userReducer"
import toastifyReducer from "./toastifyReducer"
import bookingReducer from "./bookingReducer"
import employeeReducer from "./employeeReducer"
import customerReducer from "./customersReducer"

const rootReducer = combineReducers({
  userReducer,
  toastifyReducer,
  bookingReducer,
  employeeReducer,
  customerReducer,
})
export default rootReducer
