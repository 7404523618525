import React, { useEffect, useRef, useState } from "react"
import { uploadImage } from "../../../../axios/api/apiModule"
import { addDoc, collection, doc } from "firebase/firestore"
import { db } from "../../../../firebase-config"

const ImageUpload = ({ imageURLS }) => {
  const [isImageUpload, setIsImageUpload] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [imageData, setImageData] = useState([])

  //Add image to Firebase (Start)
  const mediaCollectionRef = collection(db, "media")
  const saveDataFireBase = async (urls) => {
    const values = {
      imgURL: urls,
    }

    try {
      const res = await addDoc(mediaCollectionRef, { ...values })
      console.log("New image added successfully.", res)
    } catch (error) {
      console.error("Error checking or adding document: ", error)
    }
  }
  //Add image to Firebase (End)

  // uploadFile function
  const uploadFile = async (e) => {
    try {
      const response = await uploadImage(e)
      if (response.urls?.length > 0) {
        imageURLS(response.urls)
        setImageData(response.urls)
        saveDataFireBase(response.urls)
        setIsImageUpload("Uploading Completed")
      }
      console.log("Uploaded images response:", response)
    } catch (error) {
      console.error("Error uploading files:", error)
      console.error(error?.response?.data)
    }

    setTimeout(() => {
      setIsImageUpload("")
    }, 1000)
    setIsLoading(false)
  }

  const handleImages = (event) => {
    const { name, files } = event.target
    // console.log(files)

    if (files.length > 0) {
      setIsImageUpload("Uploading")
      setIsLoading(true)
      setTimeout(() => {
        uploadFile(files)
      }, 600)
    }
    // console.log(files)
  }

  const [copySuccess, setCopySuccess] = useState("Copy")
  const textRef = useRef(null)

  function copyToClipboard(e) {
    textRef.current.select()
    document.execCommand("copy")
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus()
    setCopySuccess("Copied!")
    setTimeout(() => {
      setCopySuccess("Copy")
    }, 1000)
  }

  return (
    <div className="">
      <input type="file" onChange={handleImages} disabled={isLoading} />
      <span>{isImageUpload}</span>
      
      {imageData.length ? (
        <div className="space-x-2">
          <input type="text" value={imageData} name="" id="" ref={textRef} />
          <button
            onClick={copyToClipboard}
            className=" bg-yellow-light py-1 w-20 uppercase rounded"
          >
            {copySuccess}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ImageUpload
