export const REDUX_STORE_DEFAULTS = {
  UPDATE_LOGIN_USER: () => `UPDATE_LOGIN_USER`,
  UPDATE_BOOKING_DATA: () => `UPDATE_BOOKING_DATA`,
  UPDATE_MOBILE_BOOKING_DATA: () => `UPDATE_MOBILE_BOOKING_DATA`,
  UPDATE_SHOP_BOOKING_DATA: () => `UPDATE_SHOP_BOOKING_DATA`,
}

export const REDUX_TOASTIFY_DEFAULTS = {
  UPDATE_TOASTIFY_SUCCESS: () => `SUCCESS`,
  UPDATE_TOASTIFY_WARN: () => `WARN`,
  UPDATE_TOASTIFY_INFO: () => `INFO`,
  UPDATE_TOASTIFY_ERROR: () => `ERROR`,
  UPDATE_TOASTIFY_DEFAULT: () => `DEFAULT`,
}

export const REDUX_EMPLOYEE_DEFAULTS = {
  GET_EMPLOYEES: () => `GET_EMPLOYEES`,
}

export const REDUX_CUSTOMER_DEFAULTS = {
  GET_EMPLOYEES: () => `GET_CUSTOMERS`,
}
