import { REDUX_STORE_DEFAULTS } from "../config"

const initialState = {
  isBookingUpdated: false,
  mobileBookingData: {},
  shopBookingData: {},
  // Add other variables here
}

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_STORE_DEFAULTS.UPDATE_MOBILE_BOOKING_DATA():
      return {
        ...state,
        mobileBookingData: action.payload,
      }
    case REDUX_STORE_DEFAULTS.UPDATE_SHOP_BOOKING_DATA():
      return {
        ...state,
        shopBookingData: action.payload,
      }
    case REDUX_STORE_DEFAULTS.UPDATE_BOOKING_DATA():
      return {
        ...state,
        isBookingUpdated: action.payload,
      }

    default:
      return state
  }
}

export default bookingReducer
