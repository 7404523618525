import axios from "axios"
// Function to get the token (modify as needed for your storage solution)
const getToken = () => {
  const data = localStorage.getItem("user")
  const userData = JSON.parse(data)
  // console.log(JSON.parse(data), "local")
  return userData?.token ?? "" // Adjust according to your token storage solution
}

// Create axios instance
const axiosInstance = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1/",
  baseURL: 'https://api.boostceramic.ca/api/v1/',
  redirect: "follow",
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers["bearer-token"] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
