import React, { useEffect, useRef, useState } from "react"
import { getAllBookings, getAllEmployee } from "../../axios/api/apiModule"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { bookingAction } from "../store/actions/bookingAction"
import {
  REDUX_CUSTOMER_DEFAULTS,
  REDUX_EMPLOYEE_DEFAULTS,
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../store/config"
import { employeeAction } from "../store/actions/employeeAction"
import { customersAction } from "../store/actions/customersAction"
import CryptoJS from "crypto-js"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../../firebase-config"
import { openToastify } from "../store/actions/toastifyAction"
import { useNavigate } from "react-router-dom"
import { userAction } from "../store/actions/userAction"

const GetDataApi = () => {
  const navigate = useNavigate()
  const [mobileData, setMobileData] = useState([])
  const [shopData, setShopData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditData, setIsEditData] = useState()
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const hasRunOnce = useRef(false)
  const dispatch = useDispatch()
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )

  //booking data
  const mobileTimeSlots = [
    "8:00am",
    "8:30am",
    "9:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
  ]
  const shopTimeSlots = [
    "9:30am",
    "9:45am",
    "10:00am",
    "12:30pm",
    "12:45pm",
    "1:00pm",
    "4:00pm",
    "4:15pm",
    "4:30pm",
  ]

  const handleSessionExpireError = () => {
    const value = {
      token: "",
      lastActivity: "",
    }
    localStorage.setItem("user", JSON.stringify(value))
    dispatch(userAction(REDUX_STORE_DEFAULTS.UPDATE_LOGIN_USER(), ""))
  }

  const generateHash = (s) => {
    // Hash the string using SHA-256
    const hash = CryptoJS.SHA256(s).toString(CryptoJS.enc.Hex)

    // Convert the first 8 characters of the hash to an integer
    const largeNumber = parseInt(hash.substring(0, 8), 16)

    // Map the large number to a 4-digit number
    const fourDigitHash = largeNumber % 10000

    return fourDigitHash.toString().padStart(4, "0") // Ensure it's always 4 digits
  }

  const hitGetbookingApiMobile = async (page = 1, pageSize = 50) => {
    const params = {
      locationType: "mobile",
      page,
      pageSize,
    }
    try {
      const response = await getAllBookings(params)
      console.log(response)
      if (response.success) {
        setMobileData(response.data)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 429) {
        dispatch(
          openToastify(
            "Too many requests, Please try again after 10 minutes",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (error?.response?.status === 401) {
        dispatch(
          openToastify(
            "Session Expired Login Again !",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        handleSessionExpireError()
      } else {
        dispatch(
          openToastify(
            "Somthing Went worng, Please try again later",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
          )
        )
      }
    }
  }
  const hitGetbookingApiShop = async (page = 1, pageSize = 50) => {
    const params = {
      locationType: "shop",
      page,
      pageSize,
    }
    try {
      const response = await getAllBookings(params)
      console.log(response)
      if (response.success) {
        setShopData(response.data)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 429) {
        dispatch(
          openToastify(
            "Too many requests, Please try again after 10 minutes",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (error?.response?.status === 401) {
        dispatch(
          openToastify(
            "Session Expired Login Again !",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        handleSessionExpireError()
      } else {
        dispatch(
          openToastify(
            "Somthing Went worng, Please try again later",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
          )
        )
      }
    }
  }

  const hitGetbookingApiMobileDate = async (page = 1, pageSize = 50) => {
    const params = {
      locationType: "mobile",
      // page
      // pageSize
    }
    try {
      const response = await getAllBookings(params)
      console.log(response)
      if (response.success) {
        setMobileData(response.data.bookings)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const hitGetbookingApiShopDate = async (page = 1, pageSize = 50) => {
    const params = {
      locationType: "shop",
      startDate: "2024-07-01",
      endDate: "2024-09-01",
      pageSize: 100,
    }
    try {
      const response = await getAllBookings(params)
      console.log(response)
      if (response.success) {
        setShopData(response.data.bookings)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addTimeToDateString = (dateString, time) => {
    // Create a moment object from the input date string
    let date = moment(dateString).tz("UTC")

    // Extract hours and minutes from the time string
    const [timePart, period] = time.split(/(am|pm)/i)
    let [hours, minutes] = timePart.split(":").map(Number)

    // Convert hours to 24-hour format if necessary
    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0
    }

    // Set the desired time
    date.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })

    // date.add(1, 'hours');

    // Return the updated date string in ISO format
    return date.toISOString()
  }

  const addSomeBookingData = (bookingData) => {
    return bookingData?.bookings?.map((booking) => {
      const timeSlots =
        booking.locationType === "mobile" ? mobileTimeSlots : shopTimeSlots
      const timeSlot = timeSlots[booking.bookingSlot - 1] // -1 to convert slot number to index
      let updatedDate
      if (booking.adminAuthored) {
        updatedDate = booking.bookingDate
      } else {
        updatedDate = addTimeToDateString(booking.bookingDate, timeSlot)
        // console.log(booking.bookingDate, booking.adminAuthored, updatedDate)
      }
      // Convert the booking date from UTC to local time in America/Vancouver timezone
      // const updatedDate = moment(booking.bookingDate)
      //   .tz("America/Vancouver").format()

      return {
        ...booking,
        bookingTimeSlot: updatedDate,
        bId: generateHash(booking._id),
        // bookingDate: updatedDate,
      }
    })
  }

  useEffect(() => {
    if (!hasRunOnce) return
    setIsLoading(true)
    hitGetbookingApiMobile()
    hitGetbookingApiShop()
    hasRunOnce.current = true
    console.log("isBookingUpdated", isBookingUpdated)
  }, [isBookingUpdated])

  useEffect(() => {
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_MOBILE_BOOKING_DATA(), {
        bookings: addSomeBookingData(mobileData),
        pagination: mobileData?.pagination,
      })
    )
    dispatch(
      bookingAction(REDUX_STORE_DEFAULTS.UPDATE_SHOP_BOOKING_DATA(), {
        bookings: addSomeBookingData(shopData),
        pagination: shopData?.pagination,
      })
    )

    // hasRunOnce.current = false
  }, [mobileData, shopData])
  //booking data end

  //Get employee

  //getAllEmployee
  const empHasRunOnce = useRef(false)
  const [isEmpData, setIsEmpData] = useState([])
  const hitGetEmployeeApi = async () => {
    try {
      const response = await getAllEmployee()
      if (response.success) {
        setIsEmpData(response.msg)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 429) {
        dispatch(
          openToastify(
            "Too many requests, Please try again after 10 minutes",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (error?.response?.status === 401) {
        dispatch(
          openToastify(
            "Session Expired Login Again !",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
        handleSessionExpireError()
      } else {
        dispatch(
          openToastify(
            "Somthing Went worng, Please try again later",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
          )
        )
      }
    }
  }

  useEffect(() => {
    if (!empHasRunOnce) return
    hitGetEmployeeApi()
    empHasRunOnce.current = true
    console.log("isBookingUpdated", isBookingUpdated)
  }, [isBookingUpdated])

  useEffect(() => {
    dispatch(employeeAction(REDUX_EMPLOYEE_DEFAULTS.GET_EMPLOYEES(), isEmpData))
    empHasRunOnce.current = false
  }, [isEmpData])
  //employee data end

  // get customer data

  // get All customer data from Firebase
  const customerHasRunOnce = useRef(false)
  const usersCollectionRef = collection(db, "booked-clients")
  // Function to get all documents from a specific collection
  const getAllDocsFromFirebase = async () => {
    try {
      const querySnapshot = await getDocs(usersCollectionRef)
      // Create an array to store all documents
      const allDocs = []
      querySnapshot.forEach((doc) => {
        allDocs.push({ id: doc.id, ...doc.data() }) // Push document data and ID into the array
      })

      // console.log("GET_customer All Documents: ", allDocs)
      dispatch(
        customersAction(REDUX_CUSTOMER_DEFAULTS.GET_EMPLOYEES(), allDocs)
      )

      return allDocs
    } catch (error) {
      console.error("Error fetching documents: ", error)
      return []
    }
  }

  useEffect(() => {
    getAllDocsFromFirebase()
    if (!customerHasRunOnce) return
    customerHasRunOnce.current = true
  }, [])
  // customer data end

  // other...

  return <></>
}

export default GetDataApi
