import React from "react"
import { MdOutlineCancel } from "react-icons/md"

import { Button } from "."
import { useStateContext } from "../contexts/ContextProvider"
import { useDispatch } from "react-redux"
import { userAction } from "../store/actions/userAction"
import { REDUX_STORE_DEFAULTS } from "../store/config"
import { BsCurrencyDollar, BsShield } from "react-icons/bs"
import { FiCreditCard } from "react-icons/fi"

const UserProfile = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext()
  const dispatch = useDispatch()
  const userProfileData = [
    {
      icon: <BsCurrencyDollar />,
      title: "My Profile",
      desc: "Account Settings",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
    },
    {
      icon: <BsShield />,
      title: "My Inbox",
      desc: "Messages & Emails",
      iconColor: "rgb(0, 194, 146)",
      iconBg: "rgb(235, 250, 242)",
    },
    {
      icon: <FiCreditCard />,
      title: "My Tasks",
      desc: "To-do and Daily Tasks",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
    },
  ]

  const handleLogout = () => {
    const value = {
      token: "",
      lastActivity: "",
    }
    localStorage.setItem("user", JSON.stringify(value))
    dispatch(userAction(REDUX_STORE_DEFAULTS.UPDATE_LOGIN_USER(), ""))
    handleClick("userProfile")
  }
  return (
    <div className="nav-item absolute right-5 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w- 80">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          onClick={() => handleClick("userProfile")} // Close button functionality
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src="https://boostmobiledetailing.ca/wp-content/uploads/2024/01/boost-detailing-logo.png"
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            Boost Mobile
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            Administrator
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            info@boostmobiledetailing.ca
          </p>
        </div>
      </div>
      {/* <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div> */}
      <div className="mt-5">
        <button
          type="button"
          onClick={handleLogout}
          className={` bg-yellow-light_medium rounded-md w-full p-3 text-white hover:drop-shadow-xl hover:bg-yellow-medium_dark `}
        >
          Logout
        </button>
      </div>
    </div>
  )
}

export default UserProfile
