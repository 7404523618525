import { REDUX_STORE_DEFAULTS } from "../config"

const initialState = {
  token: '',
  // Add other variables here
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_STORE_DEFAULTS.UPDATE_LOGIN_USER():
      return {
        ...state,
        token: action.payload,
      }

    default:
      return state
  }
}

export default userReducer
