import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ContextProvider } from "./components/contexts/ContextProvider"
import { BrowserRouter } from "react-router-dom"
import { registerLicense } from "@syncfusion/ej2/base"
import { Provider } from "react-redux"
import store from "./components/store/Store"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root"))
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlecXRUQmlYUE11XEo="
)
root.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
