const toastifyId = "type_of_toastify"
const toastifyPayload = "loading"
const toastifyReducer = (state = { toastifyPayload, toastifyId }, action) => {
  // console.log("reducer",action.id);
  switch (action.type) {
    case "OPENTOASTIFY":
      return { toastifyPayload: action.payload, toastifyId: action.id }

    default:
      return state
  }
}

export default toastifyReducer
