import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import { collection, doc, getDocs, updateDoc } from "firebase/firestore"
import { db } from "../../firebase-config"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"
import ImageUpload from "../data/modal/image/ImageUpload"

const Offers = () => {
  const [offerData, setOfferData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isOffer, setIsOffer] = useState({
    animation: false,
    name: "",
    description: "",
    offerText: "",
    imgUrl: "",
    textColor: "white",
    display: false,
  })
  const usersCollectionRef = collection(db, "offers")
  const customerHasRunOnce = useRef(false)
  const dispatch = useDispatch()
  const [uploadImages, setUploadImages] = useState([])

  const getAllDocsFromFirebase = async () => {
    try {
      const querySnapshot = await getDocs(usersCollectionRef)
      // Create an array to store all documents
      const allDocs = []
      querySnapshot.forEach((doc) => {
        allDocs.push({ id: doc.id, ...doc.data() }) // Push document data and ID into the array
      })

      console.log("offers All Documents: ", allDocs)
      setOfferData(allDocs[0])

      return allDocs
    } catch (error) {
      console.error("Error fetching documents: ", error)
      return []
    }
  }
  useEffect(() => {
    if (!customerHasRunOnce) return
    getAllDocsFromFirebase()
    customerHasRunOnce.current = true
  }, [])

  const saveFirebaseApi = async () => {
    try {
      // Reference to the document you want to update
      const docRef = doc(db, "offers", offerData.id) // Replace 'collectionName' with your actual collection name

      // Update the document
      await updateDoc(docRef, isOffer)

      console.log("Document updated successfully!")
      getAllDocsFromFirebase()

      setIsOffer((prevData) => ({
        ...prevData,
        animation: false,
        name: "",
        description: "",
        offerText: "",
        imgUrl: "",
        textColor: "",
        display: null,
      }))
      dispatch(
        openToastify(
          "Offer Saved Sucessfuly",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        )
      )
    } catch (error) {
      console.error("Error updating document: ", error)
      dispatch(
        openToastify(
          "Error To Save Data",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
    setIsLoading(false)
  }
  const handleSave = () => {
    // Ensure `isOffer` is not empty before proceeding
    setIsLoading(true)
    if (isOffer.name === "") {
      dispatch(
        openToastify(
          "Enter Name",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      setIsLoading(false)
      return
    }
    if (isOffer.offerText === "") {
      dispatch(
        openToastify(
          "Enter Offer Text",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
      setIsLoading(false)
      return
    }
    // if (isOffer.display === null) {
    //   dispatch(
    //     openToastify(
    //       "Select Is Offer Public",
    //       REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
    //     )
    //   )
    //   setIsLoading(false)
    //   return
    // }

    saveFirebaseApi()
  }

  useEffect(() => {
    console.log(isOffer, isOffer.display === null)
  }, [isOffer])

  const [imageInputMode, setImageInputMode] = useState("url") // "url" or "upload"
  const handleImage = (images) => {
    console.log(images)
    setUploadImages(images)
    setIsOffer((prevData) => ({
      ...prevData,
      imgUrl: images[0],
    }))
  }

  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="" title="Offers" />

        <div className="flex flex-col">
          <span>Latest Displayed Offer</span>
          <div className="py-5">
            <span
              className={` font-semibold text-lg ${
                offerData?.display ? "text-red-500" : "text-slate-500"
              } `}
            >
              Is Live
            </span>
            <div
              className={`relative overflow-hidden sm:w-1/2 flex-1 flex flex-col items-center justify-center rounded-xl bg-yellow-medium_dark shadow shadow-white text-${offerData?.textColor}`}
            >
              {offerData?.imgUrl && (
                <img
                  src={offerData?.imgUrl}
                  alt={offerData?.name}
                  className="w-full h-[12rem] object-cover absolute"
                />
              )}
              <div className="px-5 py-3 h-[12rem] flex items-center justify-between w-full z-10">
                <div className="flex-1 w-1/2">
                  <h1 className="sm:text-3xl text-xl">{offerData?.name}</h1>
                  <p className="sm:text-base text-sm font-normal">
                    {_.truncate(offerData?.description, {
                      length: 50,
                      omission: "...",
                    })}
                  </p>
                </div>
                <div className="sm:text-4xl text-lg w-1/2 text-right">
                  <h1>{offerData?.offerText}</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-5 flex-wrap">
            <div className="flex flex-col">
              <span>Enter Name*</span>
              <input
                type="text"
                className="border-yellow-light border-2 rounded-lg h-9 w-[20rem] px-1"
                placeholder="Enter Name"
                value={isOffer?.name ?? offerData?.name}
                onChange={(e) => {
                  setIsOffer((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }))
                }}
              />
            </div>

            <div className="flex flex-col">
              <span>Enter Description*</span>
              <input
                type="text"
                className="border-yellow-light border-2 rounded-lg h-9 w-[20rem] px-1"
                placeholder="Enter Description"
                value={isOffer?.description ?? offerData?.description}
                onChange={(e) => {
                  setIsOffer((prevData) => ({
                    ...prevData,
                    description: e.target.value,
                  }))
                }}
              />
            </div>

            <div className="flex flex-col">
              <span>Enter Offer Text*</span>
              <input
                type="text"
                className="border-yellow-light border-2 rounded-lg h-9 w-[20rem] px-1"
                placeholder="Enter Offer Text"
                value={isOffer.offerText ?? offerData?.offerText}
                onChange={(e) => {
                  setIsOffer((prevData) => ({
                    ...prevData,
                    offerText: e.target.value,
                  }))
                }}
              />
            </div>

            <div>
              <div className="flex items-center gap-4">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    name="imageInputMode"
                    value="url"
                    checked={imageInputMode === "url"}
                    onChange={() => setImageInputMode("url")}
                  />
                  Enter Image URL
                </label>
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    name="imageInputMode"
                    value="upload"
                    checked={imageInputMode === "upload"}
                    onChange={() => setImageInputMode("upload")}
                  />
                  Upload Image
                </label>
              </div>
              {imageInputMode === "url" ? (
                <div className="flex flex-col">
                  {/* <span>Enter Image URL</span> */}
                  <input
                    type="text"
                    className="border-yellow-light border-2 rounded-lg h-9 w-[20rem] px-1"
                    placeholder="Enter Image URL"
                    value={isOffer?.imgUrl}
                    onChange={(e) => {
                      setIsOffer((prevData) => ({
                        ...prevData,
                        imgUrl: e.target.value,
                      }))
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  <ImageUpload imageURLS={handleImage} />
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <span>Select Text Color*</span>
              <select
                name="textColor"
                id="textColor"
                value={isOffer?.textColor ?? offerData?.textColor}
                className="capitalize w-[20rem] border-yellow-light border-2 rounded-lg h-9 px-1"
                onChange={(e) => {
                  setIsOffer((prevData) => ({
                    ...prevData,
                    textColor: e.target.value,
                  }))
                }}
              >
                <option value="black">black</option>
                <option value="white">white</option>
              </select>
            </div>

            <div className="flex flex-col">
              <span>Is Offer Public*</span>
              <select
                name="display"
                id="display"
                value={isOffer?.display ?? offerData?.display}
                className="capitalize w-[20rem] border-yellow-light border-2 rounded-lg h-9 px-1"
                onChange={(e) => {
                  setIsOffer((prevData) => ({
                    ...prevData,
                    display: e.target.value === "true",
                  }))
                }}
              >
                <option value={false}>Not Public</option>
                <option value={true}>Yes Public</option>
              </select>
            </div>
          </div>

          <button
            className="bg-yellow-light px-5 rounded-lg h-10 w-[10rem] hover:shadow-sm hover:shadow-black mt-5"
            onClick={() => {
              handleSave()
            }}
            disabled={isLoading}
          >
            {isLoading ? <span>Updating</span> : <span>Save</span>}
          </button>
        </div>
      </div>
    </>
  )
}

export default Offers
