import { AiOutlineNotification, AiOutlineAreaChart, AiOutlineCalendar, AiOutlineGift, AiOutlineShoppingCart, AiOutlineStock, AiOutlineFunnelPlot, AiOutlineTag, AiFillFileImage } from "react-icons/ai";
import { BiColorFill, BiDisc } from "react-icons/bi";
import { BiSolidDiscount } from "react-icons/bi";
import { BsKanban } from "react-icons/bs";
import { FiEdit, FiPieChart, FiShoppingBag } from "react-icons/fi";
import { IoMdContacts } from "react-icons/io";
import { RiContactsLine, RiStockLine } from "react-icons/ri";

export const links = [
    {
      title: "Dashboard",
      links: [
        {
          name: "overview",
          icon: <FiShoppingBag />,
        },
      ],
    },
  
    {
      title: "Pages",
      links: [
        {
          name: "bookings",
          icon: <AiOutlineShoppingCart />,
        },
        {
          name: "employees",
          icon: <IoMdContacts />,
        },
        {
          name: "customers",
          icon: <RiContactsLine />,
        },
        {
          name: "marketing",
          icon: <AiOutlineNotification />,
        },
        {
          name: "services",
          icon: <AiOutlineFunnelPlot />,
        },
      ],
    },
    {
      title: "Apps",
      links: [
        {
          name: "calendar",
          icon: <AiOutlineCalendar />,
        },
        {
          name: "Workflow",
          icon: <BsKanban />,
        },
        {
          name: "invoice",
          icon: <FiEdit />,
        },
        {
          name: "discount",
          icon: <AiOutlineGift />,
        },
        {
          name: "offers",
          icon: <AiOutlineTag />,
        },
        {
          name: "media",
          icon: <AiFillFileImage />,
        },
      ],
    },
    // {
    //   title: "Charts",
    //   links: [
    //     {
    //       name: "line",
    //       icon: <AiOutlineStock />,
    //     },
    //     {
    //       name: "area",
    //       icon: <AiOutlineAreaChart />,
    //     },
  
    //     // {
    //     //   name: "bar",
    //     //   icon: <AiOutlineBarChart />,
    //     // },
    //     {
    //       name: "pie",
    //       icon: <FiPieChart />,
    //     },
    //     {
    //       name: "financial",
    //       icon: <RiStockLine />,
    //     },
    //     // {
    //     //   name: "color-mapping",
    //     //   icon: <BsBarChart />,
    //     // },
    //     // {
    //     //   name: "pyramid",
    //     //   icon: <GiLouvrePyramid />,
    //     // },
    //     // {
    //     //   name: "stacked",
    //     //   icon: <AiOutlineBarChart />,
    //     // },
    //   ],
    // },
  ];
  