import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { userLogin } from "../axios/api/apiModule"
import { useDispatch, useSelector } from "react-redux"
import { userAction } from "../components/store/actions/userAction"
import { REDUX_STORE_DEFAULTS } from "../components/store/config"
import Loader from "../components/asserts/Spin.gif"

const LoginScreen = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  let { token } = useSelector((state) => state.userReducer)
  const data = localStorage.getItem("user")
  const userData = JSON.parse(data)

  const [isLoading, setIsLoading] = useState(false)

  //login
  const [values, setValues] = useState({
    phoneNumber: "",
    password: "",
  })

  //login Function
  const login = async () => {
    setIsLoading(true)
    try {
      const response = await userLogin(values)
      console.log(response)
      if (response.success) {
        const value = {
          token: response.msg.hashCode,
          lastActivity: new Date(),
        }

        localStorage.setItem("user", JSON.stringify(value))

        dispatch(
          userAction(
            REDUX_STORE_DEFAULTS.UPDATE_LOGIN_USER(),
            response.msg.hashCode
          )
        )
      }
    } catch (error) {
      console.log(error.message)
    }
    setIsLoading(false)
  }

  // useEffect(() => {
  //   if (token) {
  //     setIsLoading(false)
  //   }
  // }, [token])

  // useEffect(() => {
  //   dispatch(
  //     userAction(
  //       REDUX_STORE_DEFAULTS.UPDATE_LOGIN_USER(),
  //       userData?.token ?? ""
  //     )
  //   )
  // }, [])

  return (
    <>
      <div className="flex h-screen bg-black justify-center items-center">
        <div className="overflow-hidden flex relative h-[600px] w-[360px] shadow-lg bg-gradient-to-r from-yellow-medium_dark to-gray-800 shadow-white">
          <div className="relative z-10 h-full w-full">
            <div className="p-8 pt-20 relative space-y-5">
              <h1 className="font-semibold text-2xl">LOGIN</h1>
              <div>
                <input
                  type="number"
                  placeholder="phoneNumber"
                  className="border-transparent outline-none border-b-2 border-b-slate-700 bg-transparent p-3 pl-6 font-bold w-3/4"
                  onChange={(e) =>
                    setValues((phoneNumber) => ({
                      ...phoneNumber,
                      phoneNumber: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  className="border-transparent outline-none border-b-2 border-b-slate-700 bg-transparent p-3 pl-6 font-bold w-3/4"
                  onChange={(e) =>
                    setValues((password) => ({
                      ...password,
                      password: e.target.value,
                    }))
                  }
                />
              </div>

              {/* <div className="flex flex-col justify-center items-center space-y-4">
                <button
                  className="bg-white text-sm font-semibold mt-16 py-4 px-5 rounded-3xl w-full border border-slate-700  shadow-md shadow-black"
                  onClick={() => login()}
                >
                  <span>LOGIN NOW</span>
                </button>
                <span className='font-semibold hover:underline hover:cursor-pointer' onClick={()=>navigate("/forgetpassword")}>FORGET PASSWORD ?</span>
              </div> */}
            </div>
            {/* <div className="absolute h-36 w-32 text-center bottom-0 right-0 text-white text-lg font-semibold">
              <h1>OR</h1>
              <span
                className="underline text-sm cursor-pointer"
                onClick={() => navigate("/register")}
              >
                CREATE NEW ACC
              </span>
            </div> */}
            <div className="flex justify-center items-end mt-10 h-[42%] cursor-pointer">
              <button
                className="bg-white text-sm font-semibold mt-16 h-12 rounded-3xl w-[90%] border border-slate-700 shadow-md shadow-black"
                onClick={() => login()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <img src={Loader} alt="" className="w-6 object-cover" />
                  </div>
                ) : (
                  <span>LOGIN NOW</span>
                )}
              </button>
              {/* <span className='font-semibold hover:underline hover:cursor-pointer' onClick={()=>navigate("/forgetpassword")}>FORGET PASSWORD ?</span> */}
            </div>
          </div>
          <div className="absolute inset-0 z-0 ">
            <div className="rotate-45">
              <div className="absolute h-[400px] w-[200px] bg-yellow-light top-[220px] right-[-400px] rounded-[32px] shadow-sm shadow-white"></div>
              <div className="absolute h-[540px] w-[190px] bg-gradient-to-b from-yellow-light to-yellow-dark top-[-150px] right-[-150px] rounded-[32px] shadow-sm shadow-white"></div>
              <div className="absolute h-[220px] w-[220px] bg-yellow-medium_dark top-[-210px] right-[55px] rounded-[32px] shadow-sm shadow-white"></div>
              <div className="absolute h-[520px] w-[520px] bg-white top-[40px] right-[-90px] rounded-tr-[72px] shadow-lg shadow-black"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
