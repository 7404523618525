import React, { useEffect, useRef, useState } from "react"
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids"

import { Header } from "../UI"

import DataTable from "react-data-table-component"
import {
  deleteEmployee,
  getAllEmployee,
  registerEmployee,
  updateEmployee,
} from "../../axios/api/apiModule"
import { useDispatch, useSelector } from "react-redux"
import { openToastify } from "../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../store/config"

const Employees = () => {
  const dispatch = useDispatch()
  const hasRunOnce = useRef(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isEmpData, setIsEmpData] = useState([])
  const [isInputData, setIsInputData] = useState()
  let { employeeListData } = useSelector(
    (state) => state.employeeReducer
  )

  //registerEmployee
  const hitCreateEmployeeApi = async () => {
    try {
      const params = {
        ...isInputData,
      }
      const response = await registerEmployee(params)
      if (response.success) {
        console.log(response)
        dispatch(
          openToastify(
            "Employee Created Successfuly",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        setIsCreate(false)
        // hitGetEmployeeApi()
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          error?.response?.data?.msg,
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }


  //deleteEmployee
  const hitDeleteEmployeeApi = async (Ids) => {
    try {
      const params = {
        empIds: [Ids],
      }
      const response = await deleteEmployee(params)
      if (response.success) {
        dispatch(
          openToastify(
            response.msg?.deletedCount + " Employee Deleted Successfuly",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        // hitGetEmployeeApi()
      }
    } catch (error) {
      console.log(error)
    }
  }

  //updateEmployee
  const hitUpdateEmployeeApi = async () => {
    try {
      const params = {}
      const response = await updateEmployee(params)
      if (response.success) {
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEmpData = (event) => {
    const { name, value, type } = event.target

    setIsInputData((prev) => ({
      ...prev,
      [name]: type === "number" ? parseInt(value, 10) : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    hitCreateEmployeeApi()
  }

  useEffect(() => {
    setIsEmpData(employeeListData)
    if (hasRunOnce.current) return
    // hitGetEmployeeApi()
    hasRunOnce.current = true
  }, [])

  const edit = (id) => {}

  const remove = (id) => {
    hitDeleteEmployeeApi(id)
  }

  //date format
  const mainColumns = [
    {
      name: "Employee Id",
      selector: (row) => row.empId,
      sortable: true,
      reorder: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phoneNumber,
      sortable: true,
      reorder: true,
    },
    // {
    //   name: "Current Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   name: "No of Service",
    //   selector: (row) => row.totalServices,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   name: "Type of Employee",
    //   selector: (row) => row.type,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      name: "Joined Date",
      selector: (row) => row.joinedOn,
      sortable: true,
      reorder: true,
    },
    {
      name: "Action",
      // selector: (row) => row.paid,
      cell: (row) => (
        <div className="flex bg-white space-x-1 border-2 shadow-sm shadow-gray-700 rounded-lg p-2">
          <svg
            onClick={() => edit(row.empId)}
            className="size-5 cursor-pointer text-yellow-medium_dark"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>

          <svg
            onClick={() => remove(row.empId)}
            className="size-5 cursor-pointer text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      ),
      sortable: true,
      reorder: true,
    },
  ]

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Employees" />
      <button
        className="bg-yellow-light text-white h-10 px-3 font-medium rounded-lg hover:shadow-md"
        onClick={() => {
          setIsCreate(true)
        }}
      >
        Create Employee
      </button>
      <DataTable
        // title="Coupons List"
        columns={mainColumns}
        data={isEmpData}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="480px"
      />
      {/* <GridComponent
        dataSource={employeesData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {employeesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent> */}

      {/* Create Employes */}
      {isCreate ? (
        <div className="fixed inset-0 z-30 bg-black bg-opacity-45 backdrop-blur-sm">
          <div className="flex h-full justify-center items-center">
            <div className="bg-white lg:h-auto lg:w-[35%] w-[95%] rounded flex flex-col">
              <div
                className="flex justify-end -mt-2 -mr-2 cursor-pointer"
                onClick={() => setIsCreate(false)}
              >
                <svg
                  className="size-6 bg-white rounded border border-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="px-[5%] py-5 overflow-y-scroll">
                <h1>Create Employee:</h1>
                <form
                  onSubmit={handleSubmit}
                  className="mx-auto py-4 flex flex-col justify-between items-center w-full h-[32rem]"
                >
                  <div className="flex flex-col w-full space-y-4">
                    {/* Enter Employee Name */}
                    <div className="flex flex-col">
                      <span className="text-sm">
                        Enter Employee Name:
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="text"
                        className="h-10 rounded-md px-2 text-black border border-black"
                        placeholder="ABC"
                        required
                        name="name"
                        onChange={handleEmpData}
                      />
                    </div>

                    {/* Employee Phone Number */}
                    <div className="flex flex-col">
                      <span className="text-sm">
                        Employee Phone Number:
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="number"
                        className="h-10 rounded-md px-2 text-black border border-black"
                        placeholder="(236) 992-8610"
                        required
                        name="phoneNumber"
                        onChange={handleEmpData}
                      />
                    </div>

                    {/* Employee Email */}
                    <div className="flex flex-col">
                      <span className="text-sm">
                        Employee Email:
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="email"
                        className="h-10 rounded-md px-2 text-black border border-black"
                        placeholder="example@example.com"
                        required
                        name="email"
                        onChange={handleEmpData}
                      />
                    </div>

                    {/* Joined Date: */}
                    <div className="flex flex-col">
                      <span className="text-sm">
                        Joined Date:
                        <span className="text-red-500">*</span>
                      </span>
                      <input
                        type="date"
                        className="h-10 rounded-md px-2 text-black border border-black"
                        placeholder="(236) 992-8610"
                        required
                        name="joinDate"
                        onChange={handleEmpData}
                      />
                    </div>
                  </div>

                  <div className="flex col-span-5 space-x-5 justify-end">
                    <button
                      className="bg-gray-300 py-2 w-28 rounded shadow shadow-black"
                      onClick={() => setIsCreate(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-yellow-medium_dark py-2 w-28 rounded shadow shadow-black"
                      type="submit"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default Employees
