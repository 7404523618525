import React, { useEffect, useRef, useState } from "react"
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps"
import { useDispatch, useSelector } from "react-redux"

const API_KEY = "AIzaSyC9U_W6NDorvK8-ani4lB0jtN21lYLi8d4"

const GoogleMap = ({ onAddressSelect, data }) => {
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [isAddress, setIsAddress] = useState(data || "") // Initialize with data only once
  const [markerRef, marker] = useAdvancedMarkerRef()
  const dispatch = useDispatch()
  let {
    bookingStepsLabel,
    stepNo,
    location,
    staffCategory,
    service,
    addOnService,
    bookingSlot,
    userInfo,
    otherUserInfo,
    otherVehicleInfo,
    confirmBooking,
  } = useSelector((state) => state.bookingReducer)

  const MapHandler = ({ place, marker }) => {
    const map = useMap()

    useEffect(() => {
      if (!map || !place || !marker) return

      if (place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport)
      }

      marker.position = place.geometry?.location
    }, [map, place, marker])
    return null
  }

  const PlaceAutocomplete = ({ onPlaceSelect, prevAddress }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null)
    const [inputValue, setInputValue] = useState(prevAddress || "") // Initialize with prevAddress
    const inputRef = useRef(null)
    const places = useMapsLibrary("places")

    useEffect(() => {
      if (!places || !inputRef.current) return

      const options = {
        fields: ["geometry", "name", "formatted_address"],
      }

      setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
    }, [places])

    useEffect(() => {
      if (!placeAutocomplete) return

      placeAutocomplete.addListener("place_changed", () => {
        const place = placeAutocomplete.getPlace()
        onPlaceSelect(place)
        setInputValue(place.formatted_address) // Update input with the selected address
      })
    }, [onPlaceSelect, placeAutocomplete])

    // Handle manual input changes (if needed)
    const handleInputChange = (e) => {
      setInputValue(e.target.value)
    }

    return (
      <div className="autocomplete-container flex items-center text-black ">
        <input
          ref={inputRef}
          className="h-10 w-[100%] rounded-md px-2 py-2 border border-black"
          value={inputValue}
          required
          placeholder="Enter Your Location"
          onChange={handleInputChange}
        />
        {inputValue ? (
          <span
            className="-ml-9 cursor-pointer px-1 py-1"
            title="clear"
            onClick={() => {
              setInputValue("") // Clear the input field
              onPlaceSelect(null) // Reset selected place
            }}
          >
            <svg
              className="size-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </span>
        ) : (
          <></>
        )}
      </div>
    )
  }

  // Set initial address from data (runs only on mount)
  useEffect(() => {
    if (data && data !== isAddress) {
      setIsAddress(data)
    }
  }, [data, isAddress])

  // Update when the selected place changes, avoiding infinite loop
  useEffect(() => {
    if (selectedPlace?.formatted_address) {
      onAddressSelect(selectedPlace.formatted_address)
    }
  }, [selectedPlace, onAddressSelect])

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <div className="autocomplete-control">
        <PlaceAutocomplete
          onPlaceSelect={setSelectedPlace}
          prevAddress={isAddress}
        />
      </div>
    </APIProvider>
  )
}

export default GoogleMap
