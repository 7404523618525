import React, { useEffect, useRef, useState } from "react"
import { Header } from "../UI"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../../firebase-config"

const Media = () => {
  const customerHasRunOnce = useRef(false)
  const usersCollectionRef = collection(db, "media")
  const [IsImageData, setIsImageData] = useState([])
  // Function to get all documents from a specific collection
  const getAllDocsFromFirebase = async () => {
    try {
      const querySnapshot = await getDocs(usersCollectionRef)
      // Create an array to store all documents
      const allDocs = []
      querySnapshot.forEach((doc) => {
        allDocs.push({ id: doc.id, ...doc.data() }) // Push document data and ID into the array
      })

      console.log("GET_customer All Documents: ", allDocs)
      setIsImageData(allDocs)
      return allDocs
    } catch (error) {
      console.error("Error fetching documents: ", error)
      return []
    }
  }

  useEffect(() => {
    getAllDocsFromFirebase()
    if (!customerHasRunOnce) return
    customerHasRunOnce.current = true
  }, [])

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl pb-5">
      <Header category="" title="Media" />
      <div className="flex flex-wrap gap-5">
        {IsImageData.map((data, index) => {
          return (
            <div className="flex flex-col justify-center">
              <img
                src={data?.imgURL}
                alt=""
                className="size-[25rem] object-contain border-2"
              />
              <input type="text" value={data?.imgURL} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Media
