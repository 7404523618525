import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, DateTime, SplineAreaSeries, Legend } from '@syncfusion/ej2-react-charts';

import { ChartsHeader } from '../../UI';
import { useStateContext } from '../../contexts/ContextProvider';

const Area = () => {
  const { currentMode } = useStateContext();

  const areaChartData = [
    [
      { x: new Date(2002, 0, 1), y: 2.2 },
      { x: new Date(2003, 0, 1), y: 3.4 },
      { x: new Date(2004, 0, 1), y: 2.8 },
      { x: new Date(2005, 0, 1), y: 1.6 },
      { x: new Date(2006, 0, 1), y: 2.3 },
      { x: new Date(2007, 0, 1), y: 2.5 },
      { x: new Date(2008, 0, 1), y: 2.9 },
      { x: new Date(2009, 0, 1), y: 3.8 },
      { x: new Date(2010, 0, 1), y: 1.4 },
      { x: new Date(2011, 0, 1), y: 3.1 },
    ],
    [
      { x: new Date(2002, 0, 1), y: 2 },
      { x: new Date(2003, 0, 1), y: 1.7 },
      { x: new Date(2004, 0, 1), y: 1.8 },
      { x: new Date(2005, 0, 1), y: 2.1 },
      { x: new Date(2006, 0, 1), y: 2.3 },
      { x: new Date(2007, 0, 1), y: 1.7 },
      { x: new Date(2008, 0, 1), y: 1.5 },
      { x: new Date(2009, 0, 1), y: 2.8 },
      { x: new Date(2010, 0, 1), y: 1.5 },
      { x: new Date(2011, 0, 1), y: 2.3 },
    ],
    [
      { x: new Date(2002, 0, 1), y: 0.8 },
      { x: new Date(2003, 0, 1), y: 1.3 },
      { x: new Date(2004, 0, 1), y: 1.1 },
      { x: new Date(2005, 0, 1), y: 1.6 },
      { x: new Date(2006, 0, 1), y: 2 },
      { x: new Date(2007, 0, 1), y: 1.7 },
      { x: new Date(2008, 0, 1), y: 2.3 },
      { x: new Date(2009, 0, 1), y: 2.7 },
      { x: new Date(2010, 0, 1), y: 1.1 },
      { x: new Date(2011, 0, 1), y: 2.3 },
    ],
  ]
  const areaPrimaryXAxis = {
    valueType: "DateTime",
    labelFormat: "y",
    majorGridLines: { width: 0 },
    intervalType: "Years",
    edgeLabelPlacement: "Shift",
    labelStyle: { color: "gray" },
  }
  
  const areaPrimaryYAxis = {
    labelFormat: "{value}%",
    lineStyle: { width: 0 },
    maximum: 4,
    interval: 1,
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelStyle: { color: "gray" },
  }

  const areaCustomSeries = [
    {
      dataSource: areaChartData[0],
      xName: "x",
      yName: "y",
      name: "USA",
      opacity: "0.8",
      type: "SplineArea",
      width: "2",
    },
    {
      dataSource: areaChartData[1],
      xName: "x",
      yName: "y",
      name: "France",
      opacity: "0.8",
      type: "SplineArea",
      width: "2",
    },
    {
      dataSource: areaChartData[2],
      xName: "x",
      yName: "y",
      name: "Germany",
      opacity: "0.8",
      type: "SplineArea",
      width: "2",
    },
  ]

  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <ChartsHeader category="Area" title="Inflation Rate in percentage" />
      <div className="w-full">
        <ChartComponent
          id="charts"
          primaryXAxis={areaPrimaryXAxis}
          primaryYAxis={areaPrimaryYAxis}
          chartArea={{ border: { width: 0 } }}
          background={currentMode === 'Dark' ? '#33373E' : '#fff'}
          legendSettings={{ background: 'white' }}
        >
          <Inject services={[SplineAreaSeries, DateTime, Legend]} />
          <SeriesCollectionDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {areaCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

export default Area;
