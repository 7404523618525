import React, { useEffect, useState } from "react"
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids"

import { Header } from "../UI"
import DataTable from "react-data-table-component"
import GoogleMap from "../data/modal/google_map/GoogleMap"
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore"
import { db } from "../../firebase-config"
import { useDispatch, useSelector } from "react-redux"
import { openToastify } from "../store/actions/toastifyAction"
import {
  REDUX_CUSTOMER_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../store/config"
import moment from "moment"
import { customersAction } from "../store/actions/customersAction"
import Loader from "../asserts/Spin.gif"

const Customers = () => {
  const selectionsettings = { persistSelection: true }
  const toolbarOptions = ["Delete"]
  const editing = { allowDeleting: true, allowEditing: true }
  const [isAddNew, setisAddNew] = useState(false)
  const [isEdit, setisEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isData, setIsData] = useState({})
  const dispatch = useDispatch()
  const usersCollectionRef = collection(db, "booked-clients")
  let { customerListData } = useSelector((state) => state.customerReducer)
  const [isDataList, setIsDataList] = useState([])
  const [isFormatedDataList, setIsFormatedDataList] = useState([])
  const [isSearch, setIsSearch] = useState("")

  const remove = async (docId) => {
    console.log(docId, "docId", isFormatedDataList)

    try {
      // Create a reference to the document to be deleted
      const docRef = doc(usersCollectionRef, docId)

      // Delete the document
      await deleteDoc(docRef)

      console.log(`Document with ID: ${docId} deleted successfully!`)
      // Dispatch a success message after deletion
      dispatch(
        openToastify(
          "Document deleted successfully!",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        )
      )
      // Remove the deleted document from the local state
      const newData = isFormatedDataList.filter((item) => item.id !== docId)
      setIsFormatedDataList(newData)
      const newAllCustomerData = isDataList.filter((item) => item.id !== docId)
      setIsDataList(newAllCustomerData)
      dispatch(
        customersAction(
          REDUX_CUSTOMER_DEFAULTS.GET_EMPLOYEES(),
          newAllCustomerData
        )
      )
    } catch (error) {
      console.error("Error deleting document: ", error)
    }
  }

  const filterCustomerData = (input = "") => {
    // console.log(input, "input")

    if (!input) {
      // If no input is provided, return all data
      setIsFormatedDataList(isDataList)
      return
    }

    const isText = (value) => /^[a-zA-Z0-9@._\-+\s]+$/.test(value)
    const isPhoneNumber = (value) => /^[0-9]+$/.test(value) // Check if it's a number (digits only)

    // Filter data based on the input
    const filteredData = isDataList.filter((customer) => {
      if (isPhoneNumber(input)) {
        return String(customer.phoneNumber).includes(input)
      }
      // Check if input is text (likely email)
      else if (isText(input)) {
        return customer?.email?.toLowerCase().includes(input.toLowerCase())
      }
      return false
    })

    // console.log(filteredData, "filteredData")
    // Set the filtered data
    setIsFormatedDataList(filteredData)
  }

  useEffect(() => {
    setTimeout(() => {
      console.log(isSearch, "here isSearch")
      if (isSearch !== "") {
        filterCustomerData(isSearch)
      } else {
        setIsFormatedDataList(customerListData)
      }
    }, 1000)
  }, [isSearch])

  useEffect(() => {
    // getAllDocsFromFirebase()
    setIsDataList(customerListData)
    setIsFormatedDataList(customerListData)
  }, [])

  const editData = (data) => {
    setIsData(data)
    setisAddNew(true)
    setisEdit(true)
  }

  const customersGrid = [
    // {
    //   id: 0,
    //   name: "Image",
    //   selector: (row) => (
    //     <div>
    //       <img src={row.CustomerImage} alt="" className="w-10 rounded-lg" />
    //     </div>
    //   ),
    //   // sortable: true,
    //   // reorder: true,
    // },
    {
      id: 1,
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Phone",
      selector: (row) => row.phoneNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "From",
      selector: (row) => moment(row.createdAt).format("DD-MM-yyy"),
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Action",
      // selector: (row) => row.paid,
      cell: (row) => (
        <div className="flex bg-white space-x-1 border-2 shadow-sm shadow-gray-700 rounded-lg p-2">
          <svg
            className="size-6 hover:cursor-pointer text-green-400"
            onClick={() => editData(row)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>

          <svg
            className="w-6 h-6 hover:cursor-pointer text-red-400"
            onClick={() => remove(row.id)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      ),
      sortable: true,
      reorder: true,
    },
  ]

  const handleSubmit = (event) => {
    const { name, value, type, lang } = event.target
    setIsData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleAddressSelect = (address) => {
    console.log("Selected Address: ", address)
    handleSubmit({ target: { name: "address", value: address ?? "" } })
  }

  const hitEditDataFirebaseApi = async () => {
    try {
      const docRef = doc(db, "booked-clients", isData.id)
      const res = await updateDoc(docRef, isData)
      console.log("User Saved", res)

      // Update isFormatedDataList in a similar way
      const newFilterData = isFormatedDataList.map((item) =>
        item.id === isData.id ? { ...item, ...isData } : item
      )
      setIsFormatedDataList(newFilterData)

      // Update isDataList by replacing the updated entry
      const newAllData = isDataList.map((item) =>
        item.id === isData.id ? { ...item, ...isData } : item
      )
      setIsDataList(newAllData)
      dispatch(
        customersAction(REDUX_CUSTOMER_DEFAULTS.GET_EMPLOYEES(), newAllData)
      )
    } catch (error) {
      console.log("Error Editing Doc")
    }
    close()
  }
  const hitSaveDataFireBaseApi = async () => {
    try {
      let emailExists = false
      let phoneExists = false

      // Check if a document with the same email exists
      if (isData.email) {
        const emailQuery = query(
          usersCollectionRef,
          where("email", "==", isData.email)
        )
        const emailSnapshot = await getDocs(emailQuery)
        emailExists = !emailSnapshot.empty
      }

      // Check if a document with the same phone number exists
      if (isData.phoneNumber) {
        const phoneQuery = query(
          usersCollectionRef,
          where("phoneNumber", "==", isData.phoneNumber)
        )
        const phoneSnapshot = await getDocs(phoneQuery)
        phoneExists = !phoneSnapshot.empty
      }

      if (emailExists) {
        // If the email already exists, show a warning
        dispatch(
          openToastify(
            "Email already exists",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else if (phoneExists) {
        // If the phone number already exists, show a warning
        dispatch(
          openToastify(
            "Phone number already exists",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
          )
        )
      } else {
        // If neither exists, add the new document
        const res = await addDoc(usersCollectionRef, { ...isData })
        // Create the new data array based on the existing state and the new data
        const newAddData = [...isDataList, { ...isData, id: res.id }]
        // Update the state using the new data
        setIsDataList(newAddData)
        // console.log("Document added successfully", res)
        dispatch(
          customersAction(REDUX_CUSTOMER_DEFAULTS.GET_EMPLOYEES(), newAddData)
        )
        dispatch(
          openToastify(
            "Document added successfully",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        close()
      }
    } catch (error) {
      console.error("Error checking or adding document: ", error)
      dispatch(
        openToastify(
          "Error Adding Document",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
      close()
    }
  }

  const handleSaveCustomer = () => {
    // Check if either email or phoneNumber has a value
    setIsLoading(true)
    if (isData.email || isData.phoneNumber) {
      if (isEdit) {
        hitEditDataFirebaseApi()
      } else {
        hitSaveDataFireBaseApi()
      }
    } else {
      dispatch(
        openToastify(
          "At least one value needed, Email/Number",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_WARN()
        )
      )
    }
    setIsLoading(false)
  }

  const close = () => {
    setisAddNew(false)
    setisEdit(false)
    setIsData({})
  }
  const handleKeyDown = (e) => {
    // Prevent the default behavior for the up and down arrow keys
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Customers" />
      <div className="flex flex-1 my-3 space-x-3">
        <div className="flex items-center">
          <input
            type="text"
            className="bg-slate-100 h-9 rounded-lg py-1 pl-2 w-full placeholder:text-sm border"
            placeholder="Seach by id, ph, email..."
            minLength={3}
            value={isSearch ?? ""}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setIsSearch(e.target.value)
            }}
          />
          <div className="w-20">
            {isSearch !== "" && (
              <button
                className="bg-slate-300 w-20 h-9 rounded shadow"
                onClick={() => {
                  setIsSearch("")
                }}
              >
                Clear
              </button>
            )}
          </div>
        </div>
        <button
          className="bg-yellow-light_medium w-24 h-9 rounded shadow"
          onClick={() => {
            setisAddNew(true)
          }}
        >
          Add New
        </button>
      </div>

      <DataTable
        // title="Coupons List"
        columns={customersGrid}
        data={isFormatedDataList}
        pagination
        fixedHeader
        // selectableRows
        fixedHeaderScrollHeight="480px"
      />

      {isAddNew && (
        <>
          <div className="fixed inset-0 z-30 bg-black bg-opacity-45 backdrop-blur-sm">
            <div className="flex relative h-full justify-center items-center">
              <div className="bg-white lg:h-auto h- [80%] lg:w-[40%] w-[95%] rounded flex flex-col relative">
                <div
                  className="flex justify-end -mt-2 -mr-2 cursor-pointer"
                  onClick={() => {
                    close()
                  }}
                >
                  <svg
                    className="size-6 bg-white rounded border border-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>

                <div className="px-[5%] py-5 overflow-y-scroll">
                  <h1 className="text-lg">Informations:</h1>
                  <div>
                    <form onSubmit={handleSubmit} className="space-y-5">
                      {/* Enter Name */}
                      <div className="flex flex-col col-span- 2">
                        <span className="text-sm">
                          Enter Full Name:
                          {/* <span className="text-red-500">*</span> */}
                        </span>
                        <input
                          type="text"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Full Name"
                          value={isData.firstName ?? ""}
                          name="firstName"
                          onChange={handleSubmit}
                        />
                      </div>

                      {/* Enter Email */}
                      <div className="flex flex-col col-span- 2">
                        <span className="text-sm">
                          Enter Email:
                          {/* <span className="text-red-500">*</span> */}
                        </span>
                        <input
                          type="email"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Enter Email"
                          value={isData.email ?? ""}
                          name="email"
                          onChange={handleSubmit}
                        />
                      </div>

                      {/* Enter PhoneNumber */}
                      <div className="flex flex-col col-span- 2">
                        <span className="text-sm">
                          Enter PhoneNumber:
                          {/* <span className="text-red-500">*</span> */}
                        </span>
                        <input
                          type="number"
                          className="h-10 rounded-md px-2 text-black border border-black"
                          placeholder="Enter PhoneNumber"
                          value={isData.phoneNumber ?? ""}
                          max={10}
                          name="phoneNumber"
                          onChange={handleSubmit}
                        />
                      </div>

                      {/* Enter Address */}
                      <div className="flex flex-col col-span- 2">
                        <span className="text-sm">
                          Enter Address:
                          {/* <span className="text-red-500">*</span> */}
                        </span>
                        <GoogleMap
                          onAddressSelect={handleAddressSelect}
                          data={isData?.address ?? ""}
                        />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="space-x-3 flex justify-end pr-4 pb-4">
                  <button
                    className="bg-slate-300 w-20 h-9 rounded shadow"
                    onClick={() => {
                      close()
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-yellow-light_medium w-20 h-9 rounded shadow"
                    onClick={() => {
                      handleSaveCustomer()
                    }}
                  >
                    Save
                  </button>
                </div>

                {isLoading ? (
                  <div className="absolute bg-black inset-0 bg-opacity-20 backdrop-blur-sm flex justify-center items-center text-white flex-col">
                    <img src={Loader} alt="" className="size-14 object-cover" />
                    <span>Loading...</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Customers
