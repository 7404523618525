import { REDUX_EMPLOYEE_DEFAULTS } from "../config"

const initialState = {
    employeeListData: [],
    // Add other variables here
  }

  const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
      case REDUX_EMPLOYEE_DEFAULTS.GET_EMPLOYEES():
        return {
          ...state,
          employeeListData: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default employeeReducer