import React from "react"
import { Header } from "../UI"

const Services = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Services" />
      <div>
        <h1>comming Soon can edit services</h1>
      </div>
    </div>
  )
}

export default Services
