import React, { useEffect, useRef, useState } from "react"
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
  ResourcesDirective,
  ResourceDirective,
  TimelineViews,
} from "@syncfusion/ej2-react-schedule"
import { createElement } from "@syncfusion/ej2-base"
import { Internationalization, isNullOrUndefined } from "@syncfusion/ej2-base"
import {
  DropDownList,
  MultiSelect,
  CheckBoxSelection,
} from "@syncfusion/ej2-dropdowns"
import {
  DropDownListComponent,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns"
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs"
import { ButtonComponent } from "@syncfusion/ej2-react-buttons"
import {
  DatePickerComponent,
  DateTimePickerComponent,
} from "@syncfusion/ej2-react-calendars"

import { Header } from "../../UI"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useQuery } from "react-query"
import { capitalize, upperCase } from "lodash"
import {
  assignEmployee,
  reminderMail,
  reminderMessage,
} from "../../../axios/api/apiModule"
import { openToastify } from "../../store/actions/toastifyAction"
import {
  REDUX_STORE_DEFAULTS,
  REDUX_TOASTIFY_DEFAULTS,
} from "../../store/config"
import "./styles.css" // Import the CSS file
import { bookingAction } from "../../store/actions/bookingAction"
import { extend } from "@syncfusion/ej2-base"
import Loader from "../../../components/asserts/Spin.gif"
import CreateBooking from "../../UI/createBooking/CreateBooking"

// eslint-disable-next-line react/destructuring-assignment
const PropertyPane = (props) => <div className="mt-5">{props.children}</div>

const Scheduler = () => {
  const scheduleObj = useRef(null)
  const [currentView, setCurrentView] = useState(
    window.innerWidth < 768 ? "Day" : "Month"
  )
  let { employeeListData } = useSelector((state) => state.employeeReducer)
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )
  const dispatch = useDispatch()
  // Refs for MultiSelect components
  const empAssignObj = useRef(null)
  const [isEventData, setIsEventData] = useState({})
  const [newAssignEmp, setNewAssignEmp] = useState({})
  const [isAssEmpLoading, setIsAssEmpLoading] = useState(false)
  const [isForm, setIsForm] = useState(false)
  const [isData, setIsData] = useState()
  const [isEditData, setIsEditData] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [isBookingStatusSlide, setIsBookingStatusSlide] = useState(false)

  //change in calender or booking
  const change = (args) => {
    scheduleObj.selectedDate = args.value
    scheduleObj.dataBind()
  }

  const onDragStart = (arg) => {
    // eslint-disable-next-line no-param-reassign
    arg.navigation.enable = true
  }

  const [calendarData, setCalendarData] = useState([])

  //formatBookingData
  const formatBookingData = (data) => {
    return data.map((allBookingData, index) => {
      const {
        _id,
        firstName,
        lastName,
        phoneNumber,
        email,
        bookingStatus,
        locationType,
        bookingDate,
        bookingSlot,
        booking,
        tax,
        isWaterAvailable,
        isElectrictyAvailable,
        electricityBill,
        serviceTotal,
        addonServiceTotal,
        taxAmount,
        subTotal,
        couponDiscountAmount,
        giftCardDiscountAmount,
        totalAmount,
        bookingCnfMsg,
        empAssigned,
        createdAt,
        bookingTimeSlot,
        location,
        bId,
      } = allBookingData

      const description = `
      Customer: ${firstName} ${lastName}\nPhone: ${phoneNumber}\nEmail: ${email}\nLocation: ${
        locationType === "shop" ? locationType : location
      }\nServices Details:-\n${booking
        .map((b) => {
          const serviceInfo = `- ${upperCase(b.service.vehicleType)} - ${
            b.service.serviceDesc
          } - $${b.servicePrice}`
          const addonsInfo = b.addonService
            .map((a) => `${a.addonSericeName} - $${a.addonPrice}`)
            .join(",\n")
          const imagesInfo = b?.images
            ?.map((image, index) => `Image ${index + 1}: ${image}`)
            .join(",\n")
          return `${serviceInfo} ${
            addonsInfo ? `; \nAddon Services:- \n${addonsInfo}` : "\n"
          }`
        })
        .join(";\n\n")}\n- Water Available: ${
        isWaterAvailable ? "Yes" : "No"
      }\n- Electricity Available: ${
        isElectrictyAvailable ? "Yes" : "No"
      }\nTotal Amount: $${totalAmount}
    `

      // Calculate end time based on bookingTimeSlot and the total length of the booking
      const startTime = moment(bookingTimeSlot)
      const endTime = moment(startTime).add(1, "hours")
      const newCreated = moment(createdAt)

      // Extract all image URLs from the booking array
      const images = booking.flatMap((b) => b.images)

      return {
        Id: _id,
        Subject: `${upperCase(locationType)}: ${firstName} #${bId}`,
        Description: description.trim(),
        Location: locationType,
        StartTime: startTime.toISOString(),
        EndTime: endTime.toISOString(),
        Status: bookingStatus,
        empAssigned: empAssigned,
        CreatedAt: newCreated.toISOString(),
        Images: images, // Array of image URLs
      }
    })
  }

  useEffect(() => {
    console.log(shopBookingData, mobileBookingData)
    // Ensure the bookings are arrays, or default to an empty array
    const shopBookings = shopBookingData?.bookings || []
    const mobileBookings = mobileBookingData?.bookings || []

    const formattedBookingData = formatBookingData([
      ...shopBookings,
      ...mobileBookings,
    ])

    console.log(formattedBookingData)
    setCalendarData(formattedBookingData)
  }, [shopBookingData, mobileBookingData])
  //handleResize Calendar
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCurrentView("Day")
    } else {
      setCurrentView("Month")
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  //update booking
  const update = () => {
    dispatch(
      bookingAction(
        REDUX_STORE_DEFAULTS.UPDATE_BOOKING_DATA(),
        !isBookingUpdated
      )
    )
  }

  // email reminder to employee
  const findEmailsByEmpIds = (employeeList, empIds) => {
    return empIds?.map((empId) => {
      const employee = employeeList.find((emp) => emp.empId === empId)
      return employee ? employee.email : null
    })
  }
  const hitReminderMailApi = async () => {
    const emails = findEmailsByEmpIds(
      employeeListData,
      empAssignObj?.current?.value
    )

    try {
      const parms = {
        email: "ayushsah2016@gmail.com",
        subject: "reminder for booking id #1236",
        htmlContent:
          "<h1>Hello, World!</h1><p>This is a test email sent using Nodemailer.</p>",
      }
      const response = await reminderMail(parms)
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  //sms reminder to employee
  const findPhoneNumbersByEmpIds = (employeeList, emplyess) => {
    return emplyess.map((value) => {
      const employee = employeeList.find((emp) => emp.empId === value.empId)
      return employee ? employee.phoneNumber : null
    })
  }
  // Reminder Sms Api to Employees
  const hitReminderSmsApi = async (employees, MsgNote) => {
    const phNumbers = findPhoneNumbersByEmpIds(employeeListData, employees)

    for (let i = 0; i < phNumbers.length; i++) {
      const phoneNumber = phNumbers[i]
      const params = {
        phoneNumber: phoneNumber,
        msg: MsgNote, // Replace with actual message or `isEventData?.Note`
      }
      try {
        console.log(params, "reminderMessage")
        const response = await reminderMessage(params)
        console.log(response)
        if (response.success) {
          dispatch(
            openToastify(
              "SMS Send Successfuly to " + phoneNumber,
              REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
            )
          )
        }
      } catch (error) {
        console.error(error)
        dispatch(
          openToastify(
            error?.response?.data?.msg,
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
          )
        )
      }
      if (i < phNumbers.length - 1) {
        // Wait for the specified delay before sending the next message
        await new Promise((resolve) => setTimeout(resolve, 2000))
      }
    }
  }

  // Format Employee {name,empIds}
  const formatEmployee = (employee) => {
    return employee
      .map((value) => {
        const employee = employeeListData.find(
          (emp) => emp.empId === value.empId
        )
        if (employee) {
          return { name: employee.name, empId: employee.empId }
        }
        return null
      })
      .filter((employee) => employee !== null)
  }
  // hit Employee Assign Api
  const hitAssignEmployeeApi = async (props) => {
    setIsAssEmpLoading(true)
    try {
      const params = {
        bookingId: props.Id,
        employees: formatEmployee(props.assigned),
        locationType: props.Location,
      }
      // console.log(params, "params", props)
      const response = await assignEmployee(params)
      console.log(response, "response")
      if (response.success) {
        dispatch(
          openToastify(
            params?.employees?.length + " Employee Assigned Successfuly",
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
        hitReminderSmsApi(params.employees, props.MsgNote)
      }
    } catch (error) {
      console.log(error)
      dispatch(
        openToastify(
          error?.response?.data?.msg?.response,
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
        )
      )
    }
    // Introducing delay before calling update()
    setTimeout(() => {
      update()
      setIsAssEmpLoading(false)
    }, 3000) // 1000ms = 1 second delay, adjust the delay as needed
  }

  // removeExistedValues Employees
  const removeExistedValues = (arr1 = [], arr2 = []) => {
    const empIdsInArr1 = arr1.map((emp) => emp.empId)
    return arr2.filter((emp) => !empIdsInArr1.includes(emp.empId))
  }
  // function for Employee Assign data validation
  const handleAssign = (employees, note) => {
    const result = removeExistedValues(isEventData.empAssigned, employees)

    const data = {
      Id: isEventData.Id,
      assigned: result,
      Location: isEventData.Location,
      MsgNote: note,
    }
    // console.log(result, "hitAssignEmployeeApi")
    hitAssignEmployeeApi(data)
  }

  useEffect(() => {
    console.log(isEventData, "isEventData")
  }, [isEventData])

  // Calendar onPopupOpen
  const onPopupOpen = (args) => {
    // console.log("onPopupOpen", args)
    const data = args.data
    setIsEventData(data)
    if (args.type === "Editor") {
      // console.log(args.data, "args.data")
      setTimeout(() => {
        const saveButton = args.element.querySelector("#Save")
        const cancelButton = args.element.querySelector("#Cancel")
        // const checkBox = args.element.querySelector('#check-box');
        // checkBox.onchange = () => {
        //   if (!checkBox.checked) {
        //     saveButton.setAttribute('disabled', '');
        //   } else {
        //     saveButton.removeAttribute('disabled');
        //   }
        // };
        saveButton.onclick = () => {
          const employees = args.data.empAssigned
          const note = args.data.note
          console.log("saveButton", employees, note)
        }
        cancelButton.onclick = () => {
          scheduleObj.current.closeEditor()
        }
      }, 100)
    }
  }

  // Calendar onActionBegin
  const onActionBegin = (args) => {
    // console.log(args, "args.requestType")
    if (args?.requestType === "eventChange") {
      if (args.data.note && args.data.empAssigned.length) {
        // console.log(args.data.empAssigned, args.data.note)
        if (isEmployee) {
          const employees = args.data.empAssigned
          const note = args.data.note
          // handleAssign(employees, note)
          console.log(employees, note)
        }
        if (isBookingStatusSlide) {
        }
      }
    }
  }
  const onEventClick = (args) => {
    console.log("onEventClick", args.event)
    const data = args.event
    // setIsEventData(data)
  }

  const onClickAdd = () => {
    setIsForm(true)
    // scheduleObj.current.addEvent(Data);
  }
  const edit = (id) => {
    calendarData.map((booking) => {
      if (booking._id === id) {
        setIsData(booking)
      }
    })
    setIsForm(true)
    setIsEditData(true)
  }
  const closeEdit = () => {
    if (isForm) {
      setIsData()
      setIsForm(false)
    }
    setIsEditData(false)
  }

  //Editor Template
  const onEventRendered = (args) => {
    switch (args.data.Location) {
      case "shop":
        args.element.style.backgroundColor = "#0b8043"
        break
      case "mobile":
        args.element.style.backgroundColor = "#3f51b5"
        break
      default:
        args.element.style.backgroundColor = "#8e24aa"
        break
    }
  }
  const editorHeaderTemplate = (props) => {
    return (
      <div id="event-header">
        {isEventData !== undefined ? (
          isEventData?.Subject ? (
            <div>{isEventData?.Subject}</div>
          ) : (
            <div>Create New Event</div>
          )
        ) : (
          <div></div>
        )}
      </div>
    )
  }
  const EditorTemplate = (props) => {
    return props !== undefined ? (
      <div className="relative">
        <table
          className="custom-event-editor"
          style={{ width: "100%" }}
          cellPadding={5}
        >
          <tbody>
            <tr>
              <td>
                <div className="w-full">
                  <span className="e-textlabel">Subject</span>
                  <input
                    id="Subject"
                    className="e-field e-input"
                    type="text"
                    name="Subject"
                    style={{ width: "100%" }}
                    readOnly
                    value={isEventData.Subject}
                  />
                </div>
              </td>
              <td>
                <div className="w-full flex justify-end space-x-3">
                  {/* <span className="e-textlabel">Status</span>
                  <DropDownListComponent
                    id="Status"
                    placeholder="Choose status"
                    data-name="Status"
                    className="e-field"
                    style={{ width: "100%" }}
                    dataSource={[
                      { text: "Confirmed", value: "confirmed" },
                      { text: "Processing", value: "processing" },
                      { text: "Cancelled", value: "cancelled" },
                      { text: "Completed", value: "completed" },
                    ]}
                    fields={{ text: "text", value: "value" }}
                    readonly
                  /> */}
                  <ButtonComponent
                    id="assignEmp"
                    title="assignEmp"
                    onClick={() => {
                      setIsEmployee(true)
                    }}
                  >
                    Employee
                  </ButtonComponent>
                  <ButtonComponent
                    id="bookingStatus"
                    title="bookingStatus"
                    onClick={() => {
                      setIsBookingStatusSlide(true)
                    }}
                  >
                    Status
                  </ButtonComponent>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="w-full">
                  <span className="e-textlabel">Created At</span>
                  <DateTimePickerComponent
                    id="CreatedAt"
                    format="dd/MM/yy hh:mm a"
                    data-name="CreatedAt"
                    value={new Date(isEventData?.CreatedAt)}
                    className="e-field"
                    readonly
                  />
                </div>
              </td>
              <td>
                <div className="w-full">
                  <span className="e-textlabel">From</span>
                  <DateTimePickerComponent
                    id="StartTime"
                    format="dd/MM/yy hh:mm a"
                    data-name="StartTime"
                    value={new Date(isEventData?.StartTime)}
                    className="e-field"
                    readonly
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <span className="e-textlabel">Description</span>
                <textarea
                  id="Description"
                  className="e-field e-input"
                  name="Description"
                  rows={10}
                  cols={50}
                  style={{
                    width: "100%",
                    height: "60px !important",
                    resize: "vertical",
                  }}
                  readOnly
                  value={isEventData.Description}
                />
              </td>
            </tr>
            {isEventData.Images?.length > 0 && (
              <tr>
                <td colSpan={4}>
                  <span className="e-textlabel">Images</span>
                  <div className="flex w-[100%] overflow-x-scroll">
                    {isEventData.Images?.map((url) => {
                      return (
                        <>
                          <img src={url} alt="" className="w-48" />
                          {/* <img
                          src="https://res.cloudinary.com/dsprifizw/image/upload/v1723882081/uploads/image_a6ebd44c24f9.jpg"
                          alt=""
                          className="w-48"
                        /> */}
                        </>
                      )
                    })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {isEmployee && (
          <div className="absolute inset-0 bg-white space-y-2">
            <button
              className="flex items-center underline"
              onClick={() => {
                setIsEmployee(false)
              }}
            >
              <svg
                className="size-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
              <span>Back</span>
            </button>
            <div className="space-y-2">
              <span className="e-textlabel">Employee</span>
              <MultiSelectComponent
                id="mtselement"
                placeholder="Choose Employee"
                data-name="empAssigned"
                className="e-field"
                style={{ width: "100%" }}
                allowObjectBinding={true}
                value={
                  isEventData.empAssigned &&
                  isEventData.empAssigned.map((emp) => emp.empId)
                }
                dataSource={employeeListData ?? []}
                fields={{ text: "name", value: "empId" }}
                mode="CheckBox"
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
            <div className="space-y-2">
              <span className="e-textlabel">Note to Employee</span>
              <textarea
                id="note"
                className="e-field e-input"
                name="note"
                rows={2}
                cols={50}
                onChange={(e) => {}}
                style={{
                  width: "100%",
                  height: "60px !important",
                  resize: "vertical",
                }}
              />
            </div>
          </div>
        )}

        {isBookingStatusSlide && (
          <div className="absolute inset-0 bg-white space-y-2">
            <button
              className="flex items-center underline"
              onClick={() => {
                setIsBookingStatusSlide(false)
              }}
            >
              <svg
                className="size-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>
              <span>Back</span>
            </button>
            <div className="space-y-3 divide-y-1">
              <div className="space-y-2">
                <span className="e-textlabel">Status</span>
                <DropDownListComponent
                  id="Status"
                  placeholder="Choose status"
                  data-name="Status"
                  className="e-field"
                  style={{ width: "100%" }}
                  value={isEventData.Status}
                  dataSource={[
                    { text: "Confirmed", value: "confirmed" },
                    { text: "Processing", value: "processing" },
                    { text: "Cancelled", value: "cancelled" },
                    { text: "Completed", value: "completed" },
                  ]}
                  fields={{ text: "text", value: "value" }}
                />
              </div>
              <div className="space-y-2">
                <span className="e-textlabel">Note to Customer</span>
                <textarea
                  id="note"
                  className="e-field e-input"
                  name="note"
                  rows={2}
                  cols={50}
                  onChange={(e) => {}}
                  style={{
                    width: "100%",
                    height: "60px !important",
                    resize: "vertical",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div></div>
    )
  }
  const editorFooterTemplate = (props) => {
    return (
      <div id="event-footer">
        <div id="right-button">
          <button
            id="Save"
            className="e-control e-btn e-primary"
            data-ripple="true"
          >
            Save
          </button>
          <button
            id="Cancel"
            className="e-control e-btn e-primary"
            data-ripple="true"
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  //Quick Info Template
  const getHeaderDetails = (data) => {
    // Format the date in full format (e.g., Wednesday, August 17, 2024)
    const fullDate = moment(data.StartTime).format("dddd, MMMM D, YYYY")

    // Format the start and end times in hh:mm A format (e.g., 09:00 AM)
    const startTime = moment(data.StartTime).format("hh:mm A")
    const endTime = moment(data.EndTime).format("hh:mm A")

    // Combine the formatted date and time range
    return `${fullDate} (${startTime} - ${endTime})`
  }
  const headerTemplate = (props) => {
    return (
      <div className="quick-info-header text-white m-5">
        <div className="quick-info-header-content">
          <div className="quick-info-title">{props.Subject}</div>
          <div className="duration-text">
            {getHeaderDetails(props.StartTime)}
          </div>
        </div>
      </div>
    )
  }
  const contentTemplate = (props) => {
    return (
      <div className="quick-info-content">
        {props.elementType === "cell" ? (
          <div className="e-cell-content">
            {/* <div className="content-area">
              <TextBoxComponent id="title" placeholder="Title" />
            </div>
            <div className="content-area">
              <DropDownListComponent
                id="eventType"
                fields={{ text: "Name", value: "Id" }}
                placeholder="Choose Type"
                index={0}
                popupHeight="200px"
              />
            </div>
            <div className="content-area">
              <TextBoxComponent id="notes" placeholder="Notes" />
            </div> */}
          </div>
        ) : (
          <div className="event-content">
            <div className="flex justify-between space-x-3">
              <label>Subject:</label>
              <span className="font-semibold">{props.Subject}</span>
            </div>
            <div className="flex justify-between space-x-3">
              <label>Status:</label>
              <span className="font-semibold capitalize">{props.Status}</span>
            </div>
            {props.empAssigned?.length > 0 && (
              <div className="flex justify-between space-x-3">
                <label>Employee:</label>
                {props.empAssigned.map((emp) => (
                  <span className="font-semibold capitalize">{emp}</span>
                ))}
              </div>
            )}
            <div className="flex justify-between space-x-3">
              <label>Description:</label>
              <span className="font-semibold">{props.Description}</span>
            </div>
            {props.Images?.length > 0 && (
              <div className="flex space-x-3">
                <span className="e-textlabel">Images</span>
                <div className="flex w-[100%] overflow-x-scroll">
                  {props.Images?.map((url) => {
                    return (
                      <>
                        <img src={url} alt="" className="w-24" />
                      </>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
  const footerTemplate = (props) => {
    return (
      <div className="quick-info-footer">
        {props.elementType == "cell" ? (
          <div className="cell-footer">
            <ButtonComponent
              id="more-details"
              cssClass="e-flat"
              content="More Details"
              onClick={() => {}}
            />
            <ButtonComponent
              id="add"
              cssClass="e-flat"
              content="Add"
              isPrimary={true}
              onClick={() => {}}
            />
          </div>
        ) : (
          <div className="event-footer">
            <ButtonComponent
              id="delete"
              cssClass="e-flat"
              content="Delete"
              onClick={() => {}}
            />
            <ButtonComponent
              id="more-details"
              cssClass="e-flat"
              content="More Details"
              isPrimary={true}
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    )
  }

  const onPopupClose = (args) => {
    console.log("onPopupClose", args)
    setIsBookingStatusSlide(false)
    setIsEmployee(false)
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Calendar" />
      {/* <ScheduleComponent
        height="650px"
        ref={(schedule) => setScheduleObj(schedule)}
        selectedDate={new Date()}
        eventSettings={{ dataSource: calendarData ?? [] }}
        dragStart={onDragStart}
        currentView={currentView}
        // popupOpen={onPopupOpen}
        // editorTemplate={editorTemplate}
        editorTemplate={editorTemplate}
        editorHeaderTemplate={editorHeaderTemplate}
        actionBegin={onActionBegin}
        eventRendered={onEventRendered}
        rowAutoHeight={true}
        eventClick={onEventClick}
        showQuickInfo={true}
        // eventRendered={onEventRendered}
      > */}
      <ButtonComponent id="add" title="Add" onClick={onClickAdd}>
        Create Booking
      </ButtonComponent>
      <ScheduleComponent
        width="100%"
        height="640px"
        rowAutoHeight={true}
        // selectedDate={new Date()}
        ref={scheduleObj}
        currentView={currentView}
        eventSettings={{ dataSource: calendarData }}
        editorTemplate={EditorTemplate}
        editorHeaderTemplate={editorHeaderTemplate}
        editorFooterTemplate={editorFooterTemplate}
        showQuickInfo={true}
        eventRendered={onEventRendered}
        popupOpen={onPopupOpen}
        popupClose={onPopupClose}
        timezone={"UTC"}
        quickInfoTemplates={{
          // header: headerTemplate,
          content: contentTemplate,
          footer: footerTemplate,
        }}
      >
        <ViewsDirective>
          {["Day", "Week", "WorkWeek", "Month", "Agenda"].map((item) => (
            <ViewDirective key={item} option={item} />
          ))}
        </ViewsDirective>
        <Inject
          services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]}
        />
      </ScheduleComponent>

      {/* booking form */}
      {isForm ? (
        <>
          <CreateBooking
            data={isData}
            onClick={closeEdit}
            isEdit={isEditData}
          />
        </>
      ) : (
        <></>
      )}

      {/* <PropertyPane>
        <table style={{ width: "100%", background: "white" }}>
          <tbody>
            <tr style={{ height: "50px" }}>
              <td style={{ width: "100%" }}>
                <DatePickerComponent
                  value={new Date()}
                  showClearButton={false}
                  placeholder="Current Date"
                  floatLabelType="Always"
                  change={change}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PropertyPane> */}
    </div>
  )
}

export default Scheduler
