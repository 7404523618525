import { useDispatch } from "react-redux"
import { reminderMail, reminderMessage } from "../../../axios/api/apiModule"
import { openToastify } from "../../store/actions/toastifyAction"
import { REDUX_TOASTIFY_DEFAULTS } from "../../store/config"

export const SendMsgEmail = async (
  typeOfNotification,
  subject,
  msgContent,
  recipient,
  dispatch
) => {
  // Function to send SMS
  const hitReminderSMSApiClient = async (phoneNumber, msg) => {
    try {
      const params = { phoneNumber, msg }
      const response = await reminderMessage(params)
      if (response.success) {
        dispatch(
          openToastify(
            `SMS sent successfully to ${phoneNumber}`,
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
      }
    } catch (error) {
      dispatch(
        openToastify(
          error?.response?.data?.msg || "Failed to send SMS",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  // Function to send Email
  const hitReminderEmailApiClient = async (email, subject, msgContent) => {
    try {
      const params = {
        email,
        subject,
        htmlContent: msgContent,
      }
      const response = await reminderMail(params)
      if (response.success) {
        dispatch(
          openToastify(
            `Email sent successfully to ${email}`,
            REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_SUCCESS()
          )
        )
      }
    } catch (error) {
      dispatch(
        openToastify(
          error?.response?.data?.msg || "Failed to send Email",
          REDUX_TOASTIFY_DEFAULTS.UPDATE_TOASTIFY_ERROR()
        )
      )
    }
  }

  // Handle notification based on type
  if (typeOfNotification === "email") {
    await hitReminderEmailApiClient(recipient, subject, msgContent)
  } else if (typeOfNotification === "sms") {
    await hitReminderSMSApiClient(recipient, msgContent)
  }
}
