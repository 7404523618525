import React, { useEffect, useRef, useState } from "react"
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor"
import logo from "../asserts/Logo_svg.svg"
import { Header } from "../UI"
import moment from "moment"
import { capitalize, upperCase } from "lodash"
import DataTable from "react-data-table-component"
import { useDispatch, useSelector } from "react-redux"
import { AppointmentData } from "../config/bookingDataConfig"
import * as htmlToImage from "html-to-image"
import { SendMsgEmail } from "../data/modal/SendMsgEmail"

const Invoice = () => {
  const [bookingData, setBookingData] = useState([])
  let { shopBookingData, mobileBookingData, isBookingUpdated } = useSelector(
    (state) => state.bookingReducer
  )
  const [isInvoice, setIsInvoice] = useState({})
  const [isInvoiceComponent, setIsInvoiceComponent] = useState(false)
  const dispatch = useDispatch()
  const [isLoader, setIsLoader] = useState(false)

  useEffect(() => {
    const sortedBookingData = [
      ...mobileBookingData?.bookings,
      ...shopBookingData?.bookings,
    ].sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
    setBookingData(sortedBookingData)
  }, [shopBookingData, mobileBookingData])

  const InvoiceComponent = () => {
    const invoiceRef = useRef(null) // Use ref to target the component

    // Function to download as HTML
    const downloadInvoice = async () => {
      setIsLoader(true)
      const invoiceHtml = invoiceRef.current.outerHTML // Get HTML content of the component

      // Create a Blob from the HTML content
      const blob = new Blob([invoiceHtml], { type: "text/html" })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = "invoice.html" // Filename of the downloaded file

      // Programmatically click the link to trigger the download
      link.click()

      setIsLoader(false)
    }

    const handleSendInvoice = () => {
      setIsLoader(true)
      const invoiceHtml = invoiceRef.current.outerHTML

      const typeOfNotification = "email" //email or "sms"
      const subject = "Invoice from Boost Deatilng Booking"
      const msgContent = invoiceHtml
      const recipient = isInvoice.email
      // Call the SendMsgEmail function
      SendMsgEmail(typeOfNotification, subject, msgContent, recipient, dispatch)
      setIsLoader(false)
    }

    console.log(isInvoice, "isInvoice")

    return (
      <>
        <div
          ref={invoiceRef}
          style={{
            backgroundColor: "#edf2f7",
            padding: "32px",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "24px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "32px",
              }}
            >
              <div>
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginBottom: "16px",
                  }}
                >
                  Invoice
                </h1>
                <address style={{ fontStyle: "normal" }}>
                  <p style={{ fontWeight: "500" }}>{isInvoice?.firstName}</p>
                  <p
                    style={{
                      width: "128px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {isInvoice?.location}
                  </p>
                </address>
              </div>
              <div style={{ position: "relative", maxWidth: "160px" }}>
                <img
                  alt="Logo"
                  src={logo}
                  style={{ width: "80px", height: "auto" }}
                />
                <input
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    inset: 0,
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
              </div>
            </header>

            <article style={{ marginBottom: "32px" }}>
              <table
                style={{
                  width: "100%",
                  fontSize: "14px",
                  marginBottom: "24px",
                }}
              >
                <tbody>
                  <tr>
                    <th style={{ paddingRight: "16px", textAlign: "right" }}>
                      Invoice #
                    </th>
                    <td
                      contentEditable
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {isInvoice?.bId}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ paddingRight: "16px", textAlign: "right" }}>
                      Date
                    </th>
                    <td
                      contentEditable
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {moment().format("MMMM DD, yy")}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ paddingRight: "16px", textAlign: "right" }}>
                      Amount Due
                    </th>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      <span
                        id="prefix"
                        contentEditable
                        style={{ paddingRight: "4px" }}
                      >
                        $
                      </span>
                      <span contentEditable>{isInvoice?.totalAmount}</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  width: "100%",
                  fontSize: "14px",
                  tableLayout: "fixed",
                  marginBottom: "24px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#edf2f7" }}>
                    <th style={{ padding: "8px", width: "25%" }}>Item</th>
                    <th style={{ padding: "8px", width: "33%" }}>
                      Description
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        width: "16%",
                        textAlign: "right",
                      }}
                    >
                      Rate
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        width: "16%",
                        textAlign: "right",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        width: "16%",
                        textAlign: "right",
                      }}
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                {isInvoice?.booking.map((data, index) => (
                  <tbody key={index}>
                    <tr style={{ borderBottom: "1px solid #ccc" }}>
                      <td style={{ padding: "8px" }}>
                        <span contentEditable>
                          {data?.service?.vehicleType} -{" "}
                          {data?.service?.serviceName}
                        </span>
                      </td>
                      <td style={{ padding: "8px" }} contentEditable>
                        {data?.service?.serviceDesc}
                      </td>
                      <td
                        style={{ padding: "8px", textAlign: "right" }}
                        contentEditable
                      >
                        ${data?.service?.price}
                      </td>
                      <td
                        style={{ padding: "8px", textAlign: "right" }}
                        contentEditable
                      >
                        {data?.totalLength}
                      </td>
                      <td style={{ padding: "8px", textAlign: "right" }}>
                        ${data?.servicePrice}
                      </td>
                    </tr>
                    {data.addonService.length > 0 && (
                      <tr style={{ borderBottom: "1px solid #ccc" }}>
                        <td style={{ padding: "8px", textAlign: "right" }}>
                          Addon Services
                        </td>
                      </tr>
                    )}
                    {data.addonService.map((value, addonIndex) => {
                      const selectedAddon = AppointmentData.find(
                        (location) =>
                          location.locationRole ===
                          upperCase(isInvoice?.locationType)
                      )
                        ?.staff.find(
                          (staff) => staff.type === data?.service?.vehicleType
                        )
                        ?.servicesList.find(
                          (service) =>
                            service.type === data?.service?.serviceName
                        )
                        ?.services.find(
                          (service) => service?.id === data?.service.serviceId
                        )
                        ?.addonService?.find(
                          (addonService) =>
                            addonService?.id === value?.addonServiceId
                        )

                      return (
                        <tr
                          key={addonIndex}
                          style={{ borderBottom: "1px solid #ccc" }}
                        >
                          <td style={{ padding: "8px", textAlign: "right" }}>
                            {addonIndex + 1}
                          </td>
                          <td style={{ padding: "8px" }} contentEditable>
                            {selectedAddon?.serviceDisplayName}
                          </td>
                          <td
                            style={{ padding: "8px", textAlign: "right" }}
                            contentEditable
                          >
                            ${selectedAddon?.price}
                          </td>
                          <td
                            style={{ padding: "8px", textAlign: "right" }}
                            contentEditable
                          >
                            1
                          </td>
                          <td style={{ padding: "8px", textAlign: "right" }}>
                            ${selectedAddon?.price}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ))}
              </table>
            </article>

            <table
              style={{ width: "100%", fontSize: "14px", marginBottom: "32px" }}
            >
              <tbody>
                <tr>
                  <th style={{ paddingRight: "16px", textAlign: "right" }}>
                    Sub Total
                  </th>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "right",
                    }}
                  >
                    ${isInvoice?.subTotal}
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: "16px", textAlign: "right" }}>
                    Discount
                  </th>
                  <td
                    contentEditable
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "right",
                    }}
                  >
                    -${isInvoice?.couponDiscountAmount}
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: "16px", textAlign: "right" }}>
                    Tax {isInvoice?.tax}%
                  </th>
                  <td
                    contentEditable
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "right",
                    }}
                  >
                    ${isInvoice?.taxAmount}
                  </td>
                </tr>
                {isInvoice?.electricityBill && (
                  <tr>
                    <th style={{ paddingRight: "16px", textAlign: "right" }}>
                      Power Supply
                    </th>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      ${isInvoice?.electricityBill}
                    </td>
                  </tr>
                )}
                <tr>
                  <th style={{ paddingRight: "16px", textAlign: "right" }}>
                    Balance Due
                  </th>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "right",
                    }}
                  >
                    ${isInvoice?.totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>

            <aside style={{ marginTop: "32px" }}>
              <h1
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "8px",
                  marginBottom: "16px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Additional Notes
              </h1>
              <div contentEditable>
                <p>
                  A finance charge of 1.5% will be made on unpaid balances after
                  30 days.
                </p>
              </div>
            </aside>
          </div>
        </div>
        <div className="space-x-3">
          <button
            onClick={downloadInvoice}
            disabled={isLoader}
            className="mt-4 bg-yellow-light_medium text-white p-2"
          >
            <span>Download Invoice</span>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
              />
            </svg> */}
          </button>
          <button
            onClick={handleSendInvoice}
            disabled={isLoader}
            className="mt-4 bg-yellow-light_medium text-white p-2"
          >
            {isLoader ? "Sent" : "Send Invoice"}
          </button>
        </div>
      </>
    )
  }

  const handleInvoice = (data = {}) => {
    console.log("handleInvoice", data)
    setIsInvoice(data)
    setIsInvoiceComponent(true)
  }

  //booking table
  const bookingGrid = [
    {
      name: "ID",
      selector: (row) => "#" + row.bId,
      sortable: true,
      // reorder: true,
    },
    {
      name: "Personal Info",
      // selector: (row) => moment(row.bookingTimeSlot).format("hh:mm A"),
      selector: (row) => row.firstName,
      // sortable: true,
      // reorder: true,
    },
    // {
    //   name: "Appointment Time",
    //   selector: (row) =>
    //     moment(row.bookingTimeSlot).utc().format("YYYY-MM-DD hh:mm A"),
    //   sortable: true,
    //   // reorder: true,
    // },
    // {
    //   name: "Booking Type",
    //   selector: (row) => capitalize(row.locationType),
    //   // sortable: true,
    //   // reorder: true,
    // },
    {
      name: "Amount",
      selector: (row) => "$" + row.totalAmount.toFixed(2),
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.bookingStatus,
      sortable: true,
      reorder: true,
    },
    {
      name: "Invoice Status",
      selector: (row) => "N/a",
      sortable: true,
      // reorder: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex bg-white space-x-1 border-2 shadow-sm shadow-gray-700 rounded-lg p-2">
          <svg
            onClick={() => handleInvoice(row)}
            className="size-5 cursor-pointer text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
        </div>
      ),
      // sortable: true,
      // reorder: true,
    },
  ]
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Invoice" />

      <DataTable
        // title="Coupons List"
        columns={bookingGrid}
        data={bookingData ?? []}
        pagination
        fixedHeader
        // selectableRows={bookingDataType !== "all"}
        fixedHeaderScrollHeight="480px"
        // onChangePage={handlePageChange}
        // paginationTotalRows={100}
        // onSelectedRowsChange={handleSelectedRowsChange}
      />
      {isInvoiceComponent && (
        <div className="fixed inset-0 z-30 bg-black bg-opacity-45 backdrop-blur-sm">
          <div className="flex h-full justify-center items-center">
            <div className="bg-white lg:h-[95%] h-[80%] lg:w-[75%] w-[95%] rounded flex flex-col">
              <div className="flex justify-end -mt-2 -mr-2 cursor-pointer">
                <svg
                  onClick={() => {
                    setIsInvoiceComponent(false)
                  }}
                  className="size-6 bg-white rounded border border-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="scale-95 overflow-y-scroll">
                <InvoiceComponent />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Invoice
